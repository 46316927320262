<template>
  <div class="form-group row">
    <label class="control-label col-sm-4" :class="{ [errorClass]: v.$invalid }">Срок действия</label>
    <div class="col-sm-4">
      <label class="radio-inline" v-for="c in permPeriods" :key="c">
        <input type="radio" name="pPeriod" :value="c" :checked="value == c" @change="update(c)" />
        {{c}}
      </label>
      <span v-show="calculatedPeriod && calculatedPeriod?.p != value" :class="errorClass"
        style="font-weight: bold; vertical-align: middle;"
        title="Согласно автоматическому подсчету, срок действия указан неправильно"
        >
        &nbsp;&nbsp;&nbsp;&nbsp;!&nbsp;{{ calculatedPeriod?.p }}
      </span>
    </div>
  </div>
</template>

<script>
import { calculatePeriod } from './permHelpers'
export default {
  name: 'PermissionPeriod',
  data() {
    return {}
  },
  props: {
    value: { type: Number },
    v: {
      type: Object,
      required: true,
    },
    errorClass: {
      type: String,
      default: 'has-error',
    },
    // Дата выдачи разрешения
    date: {
      type: [String, Date],
      default: null,
    },
    // Год выпуска ТС
    year: {
      type: [Number, String],
      default: null,
    },
    // Код категории ТС
    category: {
      type: Number,
      default: null,
    },
    // Код применения
    applying: {
      type: Number,
      default: null,
    },
    // Форма собственности
    legalType: {
      type: Number,
      default: null,
    }
  },
  emits: ['change'],
  methods: {
    update(newValue) {
      this.$emit('input', newValue);
      this.$emit('change', newValue)
    },
  },
  computed: {
    permPeriods() {
      let result = [6, 12, 24, 36];
      return result;
    },
    calculatedPeriod() {
      let vm = this
      let result = calculatePeriod(vm.date, vm.year, vm.category, vm.applying, vm.legalType)
      return result
    },
  },
}
</script>
