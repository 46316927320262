import axios from './axios'

const refuse = (body, { refuseApplication }) => axios.post(`oais/activeApplication/refuse/${!!refuseApplication}`, body)
const createRepresentation = (body) => axios.post('oais/createRepresentation', body)
const fulfill = (body) => axios.post('oais/fulfill', body)
const take = (body, { number }) => axios.post(`oais/activeApplication/take/${encodeURIComponent(number)}`)
const untake = (body, { number }) => axios.post(`oais/activeApplication/untake/${encodeURIComponent(number)}`)
const getActiveApplications = (body, { skip, pageSize }) => axios.get('oais/activeApplications', { params: { sk: skip, l: pageSize } })
const findTi = (body, { certNumber, regNumber }) => axios.get('oais/findTi', { params: { certNumber, regNumber } })

const entries = {
  refuse,
  createRepresentation,
  fulfill,
  take,
  untake,
  getActiveApplications,
  findTi
}

export default entries

// export default {
//   // отменить рассмотрение заявления (вернуть в нерассмотренные):
//   untake: { method: 'POST', url: 'oais/activeApplication/untake/{number}' },
//   // Отказать в принятии заявления:
//   refuseToAccept: { method: 'POST', url: 'oais/activeApplication/refuseToAccept/{number}' },
//   // Отказать в осуществлении АП:
//   refuseProcedure: { method: 'POST', url: 'oais/activeApplication/refuseProcedure/{number}' },
// };
