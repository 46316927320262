<template>
  <img :src="url" :class="{ 'img-shrinked': thumbnail }" />
</template>
<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
