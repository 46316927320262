<template>
  <div class="container-fluid">
    <select-erip-payment
      v-if="showEripPaymentSearch"
      @close="showEripPaymentSearch = false"
      width="800"
      @apply="applyPayment"
      :initialSearchTerm="initialSearchTerm"
      :payload="targetPayment"
      :allowed-services="allowedServices"
    />
    <select-webpay-payment
      v-if="showWebpayPaymentSearch"
      @close="showWebpayPaymentSearch = false"
      width="800"
      @apply="applyPayment"
      :initialSearchTerm="initialSearchTerm"
      :payload="targetPayment"
      :allowed-services="allowedServices"
    />
    <single-payment
      v-model="value.payments[i]"
      v-for="(p, i) in value.payments"
      :key="i"
      @search="search"
      @del="delPayment(i)"
      :v="v.payments.$each[i]"
      :payForms="payForms"
      :errorClass="errorClass"
    />
  </div>
</template>

<script>
import SelectEripPayment from '@/components/payments/SelectEripPayment';
import SelectWebpayPayment from '@/components/payments/SelectWebpayPayment';
import { payForms } from '@/lib/consts';
import SinglePayment from './SinglePayment';
export default {
  data() {
    return {
      showEripPaymentSearch: false,
      showWebpayPaymentSearch: false,
      targetPayment: {},
    };
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
    errorClass: {
      type: String,
      default: 'has-error',
    },
    payForms: {
      type: Array,
      required: false,
      default: () => payForms,
    },
    initialSearchTerm: {
      type: String,
      required: false,
    },
    allowedServices: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  methods: {
    search({ payment, payType }) {
      switch (payType) {
        case 3:
          this.targetPayment = payment;
          this.showEripPaymentSearch = true;
          break;
        case 7:
          this.targetPayment = payment;
          this.showWebpayPaymentSearch = true;
          break;
        default:
          break;
      }
    },
    /**
     * @param foundPayment {Object} информация о найденном платеже
     * @param payload {Object} информация, которую мы передали компоненту, осуществляющему поиск.
     *    Тут мы просто передаем и принимаем объект платежа, который будет прикреплен к документу (разрешению, акту и т.д.)
     * @param payType {Number} код платёжнй системы, в которой искали платёж. 3 - ЕРИП, 7 - Webpay
     */
    applyPayment({ foundPayment, payload, payType }) {
      this.showEripPaymentSearch = false;
      this.showWebpayPaymentSearch = false;
      if (!foundPayment) return;
      let target = payload;
      switch (payType) {
        case 3:
          target.s = foundPayment.s;
          target.payId = foundPayment.txId;
          break;
        case 7:
          target.s = foundPayment.sum;
          target.payId = foundPayment.id;
          break;
        default:
          break;
      }
    },
    delPayment(i) {
      this.value.payments.splice(i, 1);
    },
  },
  components: { SelectWebpayPayment, SelectEripPayment, SinglePayment },
};
</script>
