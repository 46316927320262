<template>
  <vue-pdf-embed style="display: inline-block; width: 30%; position: static" :source="url" />
</template>
<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
export default {
  computed: {
    style() {
      return {
        display: 'inline-block',
        ...(this.thumbnail) && { width: '30%' },
        position: 'static'
      }
    }
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: Boolean,
      default: true,
    },
  },
  components: { VuePdfEmbed },
};
</script>
