const logLevels = Object.freeze(['debug', 'info', 'warn', 'error', 'fatal'])

const defaultOptions = Object.freeze({
  isEnabled: true,
  logLevel: 'warn',
  separator: '|',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: false,
  showConsoleColors: true,
})

function print(logLevel = false, logLevelPrefix = '', methodNamePrefix = '', formattedArguments = false, showConsoleColors = false) {
  let prefix = (logLevelPrefix + methodNamePrefix).trim()
  let cons
  if (showConsoleColors && (logLevel === 'info' || logLevel === 'warn' || logLevel === 'error' || logLevel === 'fatal')) {
    cons = console[logLevel === 'fatal' ? 'error' : logLevel]
  } else {
    cons = console.log
  }
  if (prefix) {
    cons.call(console, prefix, ...formattedArguments)
  } else {
    cons.call(console, ...formattedArguments)
  }
}

function getMethodName() {
  let error = {}
  try {
    throw new Error('')
  } catch (e) {
    error = e
  }
  // IE9 does not have .stack property
  if (error.stack === undefined) {
    return ''
  }
  let stackTrace = error.stack.split('\n')[3]
  if (/ /.test(stackTrace)) {
    stackTrace = stackTrace.trim().split(' ')[1]
  }
  if (stackTrace && stackTrace.includes('.')) {
    stackTrace = stackTrace.split('.')[1]
  }
  return stackTrace
}

function isValidOptions(options) {
  if (!(options.logLevel && typeof options.logLevel === 'string' && logLevels.indexOf(options.logLevel) > -1)) {
    return false
  }
  if (options.stringifyArguments && typeof options.stringifyArguments !== 'boolean') {
    return false
  }
  if (options.showLogLevel && typeof options.showLogLevel !== 'boolean') {
    return false
  }
  if (options.showConsoleColors && typeof options.showConsoleColors !== 'boolean') {
    return false
  }
  if (options.separator && (typeof options.separator !== 'string' || (typeof options.separator === 'string' && options.separator.length > 3))) {
    return false
  }
  if (typeof options.isEnabled !== 'boolean') {
    return false
  }
  return !(options.showMethodName && typeof options.showMethodName !== 'boolean')
}

const LOGGER_INSTANCE = {
  setOptions(options) {
    // combined options:
    options = { ...defaultOptions, ...this.currentOptions, ...options }
    if (!isValidOptions(options, logLevels)) {
      throw new Error('Provided options for logger are not valid.')
    }
    this.currentOptions = options
    logLevels.forEach((logLevel) => {
      if (logLevels.indexOf(logLevel) >= logLevels.indexOf(options.logLevel) && options.isEnabled) {
        LOGGER_INSTANCE[logLevel] = (...args) => {
          let methodName = getMethodName()
          const methodNamePrefix = options.showMethodName ? `${methodName} ${options.separator} ` : ''
          const logLevelPrefix = options.showLogLevel ? `${logLevel} ${options.separator} ` : ''
          const formattedArguments = options.stringifyArguments ? args.map((a) => JSON.stringify(a)) : args
          print(logLevel, logLevelPrefix, methodNamePrefix, formattedArguments, options.showConsoleColors)
        }
      } else {
        LOGGER_INSTANCE[logLevel] = () => {}
      }
    })
  },
  currentOptions: {},
}

export const VueLoggerPlugin = {
  install: function(target, options) {
    target.$log = LOGGER_INSTANCE
    target.prototype.$log = LOGGER_INSTANCE
    if (options) {
      LOGGER_INSTANCE.setOptions(options)
    }
    return LOGGER_INSTANCE
  },
}

// init logger with default options:
LOGGER_INSTANCE.setOptions({
  logLevel: (process.env.NODE_ENV === 'production') ? 'warn' : 'debug'
})

export default LOGGER_INSTANCE
