import { ajax } from '@/api/api';
import axios from 'axios';
import { store } from '@/store/store';
import { AjaxError, EDS_IMPL } from './consts';
import { b64EncodeUnicode } from './funcs';

/**
 * Returns SHA-256 hash from supplied message.
 * https://gist.github.com/chrisveness/e5a07769d06ed02a2587df16742d3fdd
 * @param   {String} message.
 * @returns {String} hash as hex string.
 *
 * @example
 *   sha256('abc').then(hash => console.log(hash));
 *   const hash = await sha256('abc');
 */
async function sha256(message) {
  const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
  var bytes = new Uint8Array(hashBuffer);
  var len = bytes.byteLength;
  var binary = '';
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

/**
 * Подписывает строку простой подписью (обычный хэш SHA-256).
 * Документ и ЭЦП кодируются в Base64.
 * @param {String} dataString Объект для подписания, уже преобразованный в строку (JSON.stringify)
 * @returns {Object} подписанный объект в формате:
 * {"d": "данные в виде строки в кодировке Base64",
 * "s": "подпись в кодировке Base64"}
 */
async function signSha256(dataString, encodedData) {
  let data2sign = store.getters.edsError ? dataString + 'a' : dataString;
  let encodedSign = await sha256(data2sign);
  Vue.$log.debug(`Входные данные: ${dataString}, данные, которые подписываем: ${data2sign}`);
  return { s: encodedSign, d: encodedData, si: EDS_IMPL.SHA256, t: 0 };
}

async function signSha256Multiple(dataArray) {
  let result = dataArray.map(async(dataString) => {
    let data2sign = store.getters.edsError ? dataString + 'a' : dataString;
    return { errCode: 0, content: await sha256(data2sign) };
  });
  // wait for all promises complete:
  for (let i = 0; i < result.length; i++) {
    result[i] = await result[i];
  }
  return result;
}

/**
 * Подписывает объект ЭЦП (реализация от НТЦ Контакт + proxy от Александра Коско).
 * Документ и ЭЦП кодируются в Base64.
 * @param {String} dataString Объект для подписания, уже преобразованный в строку (JSON.stringify)
 * @returns {Object} подписанный объект в формате:
 * {"d": "данные в виде строки в кодировке Base64",
 * "s": "подпись в кодировке Base64"}
 */
async function signContact(dataString, encodedData) {
  let data2sign;
  if (store.getters.edsError) {
    // Vue.$log.debug(`Входные данные: ${dataString}, данные, которые подписываем: ${dataString + 'a'}`);
    data2sign = b64EncodeUnicode(dataString + 'a');
  } else {
    // Vue.$log.debug(`Входные данные: ${dataString}, данные, которые подписываем: ${dataString}`);
    data2sign = encodedData;
  }
  let post = JSON.stringify({ base64: data2sign });
  return ajax.sign(/* { isSign: 1 } */ null, post).then(
    (r) => {
      if (r.data.errCode == 0) {
        return Promise.resolve({ s: r.data.content, d: encodedData, si: EDS_IMPL.CONTANCT, t: 0 });
      } else {
        return Promise.reject(new AjaxError(1001, r.data.message));
      }
    },
    (e) => {
      let message = e.data && e.data.message ? e.data.message : 'Не удалось поставить ЭЦП. Возможно, не запущен криптосервис';
      return Promise.reject(new AjaxError(1001, 'Ошибка ЭЦП: ' + message));
    }
  );
}

/**
 * Подписывает объекты ЭЦП (реализация от НТЦ Контакт + proxy от Александра Коско).
 * Объекты и ЭЦП кодируются в Base64.
 * @param {Array} data Массив объектов для подписания, преобразованных в строку (JSON.stringify) и представленных в кодировке Base64
 * @returns {Object} подписанный объект в формате:
 * @example
 * {
 *   "d": "данные в виде строки в кодировке Base64",
 *   "s": "подпись в кодировке Base64"
 * }
 */
async function signAvestMultiple(data, keyId) {
  let id = 1;
  let data2sign = {
    data: data.map((data) => ({ data, id: '' + (1000000 + id++) })),
    keyId,
  }
  console.log('will sign', data2sign)
  return axios
    .post('http://127.0.0.1:49020/avest/sign', data2sign)
    .then((r) => {
      console.log('signed data', r)
      let errMsg = r.data
        .filter((i) => i.errCode != 0)
        .map((i) => i.message)
        .join('\n')
      if (errMsg) {
        return Promise.reject(new AjaxError(1001, errMsg));
      }
      return r.data
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((e) => ({ errCode: 0, content: e.signature }));
    })
    .catch((e) => {
      console.log('error in signAvestMultiple:', e);
      let message =
        e.data && e.data.message
          ? e.data.message
          : 'Не удалось поставить ЭЦП. Возможно, не запущен криптосервис';
      throw new AjaxError(1001, 'Ошибка ЭЦП: ' + message);
      // return Promise.reject(new AjaxError(1001, 'Ошибка ЭЦП: ' + message));
    });
}

/**
 * Подписывает объект ЭЦП. Реализация выбирается в зависимости от настроек.
 * Объект сначала выгоняется в строку, высчитывается ЭЦП для этой строки.
 * Документ и ЭЦП кодируются в Base64.
 * @returns {Object} подписанный объект в формате:
 * {"d": "данные в виде строки в кодировке Base64",
 * "s": "подпись в кодировке Base64"}
 */
export async function sign(obj) {
  let dataAsString = typeof obj === 'string' ? obj : JSON.stringify(obj);
  let encodedData = b64EncodeUnicode(dataAsString);
  switch (store.getters.edsImpl) {
    case EDS_IMPL.SHA256:
      return signSha256(dataAsString, encodedData);
    case EDS_IMPL.CONTANCT:
      return signContact(dataAsString, encodedData);
    default:
      return { s: '', d: b64EncodeUnicode(dataAsString), si: EDS_IMPL.UNDEFINED, t: 0 };
  }
}

function decodeData(arrayOfStrings) {
  throw new Error('Not implemented');
}

function encodeData(arrayOfStrings) {
  return arrayOfStrings.map((item) => b64EncodeUnicode(item));
}

function stringifyData(arrayOfMixedData) {
  let injectError = store.getters.edsError;
  return arrayOfMixedData.map((item) => (typeof item === 'string' ? item : JSON.stringify(item)) + (injectError ? 'a' : ''));
}

/**
 * Подписывает объект(ы) ЭЦП. Реализация выбирается в зависимости от настроек.
 * Объект сначала выгоняется в строку, высчитывается ЭЦП для этой строки.
 * Документ и ЭЦП кодируются в Base64.
 * @param toBase64 {boolean} преобразовывать ли объект(ы) в кодировку Base64.
 *    Преобразуются все объекты или ни один.
 *    Если false, входные данные ожидаются уже закодированными в base64.
 * @param payload {Array} массив объектов для подписания
 * @param keyId {String} идентификатор ключа ЭЦП. Необязательный параметр.
 *
 * @example
 * [
 *   { "some": "object" },
 *   "Some string"
 * ]
 *
 * @example
 * [
 *  "MTYg0YHQtdC9IDIwMjQg0LMuIDExOjQ5OjA1Cg==",
 *  "MTYg0YHQtdC9IDIwMjQg0LMuIDExOjQ5OjE1Cg=="
 * ]
 *
 * @returns {Array} подписанный(е) объект(ы) в формате:
 * @example
 * [
 *   {
 *     "d": "данные в виде строки в кодировке Base64",
 *     "s": "подпись в кодировке Base64",
 *     "si": 0 // реализация алгоритма подписания
 *   },
 * ]
 */
export async function signMultiple(payload, payloadEncoded = false, keyId) {
  // console.log(`signMultiple: payloadEncoded: ${payloadEncoded}`);
  // console.log(`signMultiple: keyId: ${keyId}`);
  // console.log('signMultiple: payload:');
  // console.log(payload);
  if (!payload.length) return {};
  const edsImpl = EDS_IMPL.AVEST; // store.getters.edsImpl;
  let dataStringified = null,
    dataEncoded = null,
    dataSigned = null;
  if (payloadEncoded) {
    dataEncoded = payload;
  }
  switch (edsImpl) {
    case EDS_IMPL.SHA256: {
      dataStringified = dataStringified || (payloadEncoded ? decodeData(payload) : stringifyData(payload));
      dataSigned = signSha256Multiple(dataStringified);
      break;
    }
    case EDS_IMPL.AVEST: {
      dataEncoded = payloadEncoded ? payload : encodeData(stringifyData(payload));
      dataSigned = signAvestMultiple(dataEncoded, keyId);
      break;
    }
    default:
      throw new Error('unknown signing scheme');
  }
  let chain = dataSigned
    // .then(r => {
    //   // внести ошибку специально
    //   r[0].errCode = 1;
    //   return r;
    // })
    .then((r) => {
      let indexes = [];
      for (let i = 0; i < r.length; i++) {
        if (r[i].errCode != 0) indexes.push(i);
      }
      if (indexes.length) {
        throw new Error('Signing error(s) at index(es): ' + indexes.join(', '));
      }
      let result = [];
      dataEncoded = dataEncoded || (payloadEncoded ? payload : encodeData(payload));
      for (let i = 0; i < r.length; i++) {
        result.push({
          s: r[i].content,
          d: dataEncoded[i],
          si: edsImpl,
        });
      }
      return result;
    });

  return chain;
}
