<template>
  <a target="_blank" :href="url">
    <div style="border: solid 1px; display: flex; align-items: center; justify-content: center;">
      <component :is="viewerName" :url="url" :mime-type="realMimeType" :thumbnail="thumbnail" />
    </div>
  </a>
</template>

<script>
import PdfViewer from './PdfViewer.vue';
import ImageViewer from './ImageViewer.vue';
export default {
  data() {
    return {
      type: '',
      realMimeType: ''
    };
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    mimeType: {
      type: String,
      required: false,
    },
    thumbnail: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    viewerName() {
      return (this.type == 'pdf') ? PdfViewer : ImageViewer;
    },
  },
  components: { PdfViewer, ImageViewer },
  created() {
    let realMimeType = ((typeof this.mimeType !== 'string') ? '' : this.mimeType).toLowerCase();
    let type = 'img';
    switch (realMimeType) {
      case 'application/pdf':
        type = 'pdf';
        break;
      case 'image/jpg':
        break;
      default:
        realMimeType = 'image/png';
        break;
    }
    this.realMimeType = realMimeType;
    this.type = type;
  }
};
</script>
