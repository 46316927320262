import 'babel-polyfill'
// import "bootstrap/dist/css/bootstrap.min.css"
import 'icheck-bootstrap/icheck-bootstrap.min.css'
// import "bootstrap/dist/js/bootstrap.min.js"
// import "bootstrap"
import VueResource from 'vue-resource'
// import VueClipboard from "vue-clipboard2"
// import Vue from 'vue';
import App from './App.vue'
import '@/css/main.css'
import VueRouter from 'vue-router'
import { store } from './store/store'
import { VueLoggerPlugin } from '@/lib/logger.js'
import Vuex from 'vuex'
import routes from './routes/index_routes'
import { appContextPath } from '@/../generated/js/generatedAppInfo.js'
import BlockUI from 'vue-blockui'
import Pagination from 'vuejs-uib-pagination'
import Vuelidate from 'vuelidate'
import TimoDialog from 't-dialogs'
// import TimoDialog from '@/components/test/t-dialog'
import VueTabs from 'vue-nav-tabs'
import 'vue-nav-tabs/themes/vue-tabs.css'
import { defaultDateTimeFormat, defaultDateFormat, AjaxError } from '@/lib/consts'
import { VueHolder } from '@/api/axios'
import VueContext from 'vue-context'
import 'vue-context/dist/css/vue-context.css'

Vue.component('vue-context', VueContext)
Vue.use(TimoDialog)
Vue.use(Vuelidate)
Vue.use(VueTabs)

// Локализация дат:
moment.locale('ru', {
  months: 'январь_февраль_март_апрель_май_июнь_июль_август_сентябрь_октябрь_ноябрь_декабрь'.split('_'),
  monthsShort: 'янв_фев_мар_апр_май_июн_июл_авг_сен_окт_ноя_дек'.split('_'),
  monthsParseExact: true,
  weekdays: 'воскресенье_понедельник_вторник_среда_четверг_пятница_суббота'.split('_'),
  weekdaysShort: 'вс_пн_вт_ср_чт_пт_сб'.split('_'),
  weekdaysMin: 'Вс_Пн_Вт_Ср_Чт_Пт_Сб'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm',
  },
  calendar: {
    sameDay: '[Сегодня в] LT',
    nextDay: '[Завтра в] LT',
    nextWeek: 'dddd [в] LT',
    lastDay: '[Вчера в] LT',
    lastWeek: 'dddd [dernier à] LT',
    sameElse: 'L',
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // Used to determine first week of the year.
  },
})
/**
 * Фильтр для преобразования количества миллисекунд с 01.01.1970, в нормальную дату:
 */
Vue.filter('msToDate', function(value) {
  if (!value) return ''
  return moment(value).format('DD.MM.YYYY')
})

/**
 * Директива для приведения вводимых символов к верхнему регистру без потери положения курсора
 * ВНИМАНИЕ: директива некорректно работает в chrome 49 (который последний для Windows XP)
 * использование:
 * <input type="text" name="test" v-model="test.testIn" v-caps>
 */
// Vue.directive("caps", {
//   update(el, y, vnode) {
//     // console.log("update ", vnode)
//     let modelPath = vnode.data.directives.find(i => i.name === "model")
//     if (!modelPath) return
//     let path = modelPath.expression.split(".")
//     var model = vnode.context
//     for (let i = 0; i < path.length - 1; i++) {
//       model = model[path[i]]
//     }
//     const start = el.selectionStart
//     el.value = el.value.toUpperCase()
//     vnode.context.$set(model, path[path.length - 1], el.value)
//     el.setSelectionRange(start, start)
//   }
// })

// import locale from "element-ui/lib/locale/lang/ru-RU"
// import 'font-awesome/css/font-awesome.min.css'

Vue.use(VueRouter)
Vue.use(VueResource)
Vue.use(Vuex)
Vue.use(VueLoggerPlugin)
Vue.use(BlockUI)
Vue.use(Pagination)
// Vue.use(VueClipboard)

const router = new VueRouter({ routes })
Vue.http.options.root = `/${appContextPath}/s/`

let mainVue = new Vue({
  //   el: '#app',
  //   template: '<App/>',
  router,
  store,
  //   components: { App }
  render: (h) => h(App),
}).$mount('#app')

VueHolder.mainVue = mainVue

Vue.http.interceptors.push(function(request, next) {
  // modify request
  // ...
  // continue to next interceptor
  next(function(response) {
    // Костыль для определения запросов к сервису ЭЦП:
    if (request.url.endsWith('/sign_str')) {
      console.log(request)
      return
    }
    try {
      // console.log(response);
      let ct = response.headers.map['content-type'] || response.headers.map['Content-Type']
      if (!(ct && ct[0].toLowerCase().startsWith('application/json'))) {
        return new AjaxError(-13, 'Expected json, got something else', response.status)
      }
      let data = response.data
      switch (data.errCode) {
        case 0: // all OK
          break
        case 401: // redirect to login page
          Vue.$log.error('Auth required')
          mainVue.$store.commit('logout')
          if (mainVue.$route.name !== 'login') {
            mainVue.$router.push({
              name: 'login',
              query: {
                returnPath: mainVue.$route.path.endsWith('/login') ? null : mainVue.$route.fullPath,
              },
            })
          }
          return new AjaxError(data.errCode, data.message, 401)
        default:
          // error occured
          Vue.$log.debug('interceptors.next errCode != 0')
          return new AjaxError(data.errCode, data.message, response.status)
      }
    } catch (x) {
      return { errCode: -10, message: x }
    }
  })
})

Vue.filter('date', function(value, format) {
  if (!value) return ''

  return moment(value).format(format || defaultDateFormat)
})

Vue.filter('dateTime', function(value, format) {
  if (!value) return ''
  return moment(value).format(format || defaultDateTimeFormat)
})

Vue.prototype.$showError = (msg, err) => {
  Vue.$log.error(err)
  if (err.errCode > 0) {
    mainVue.$dlg.alert((msg || 'Ошибка') + ':<br>' + err.message.replace(new RegExp(/\n/g), '<br>'), {
      messageType: 'error',
    })
  } else {
    mainVue.$dlg.toast(msg || 'Системная ошибка. Обратитесь в поддержку.', { messageType: 'error' })
  }
}

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'БТО'
  // mainVue.$dlg.closeAll();
  next()
})
