<template src="./EditPermission.html"></template>

<script>
import PaymentSet from '@/components/payments/PaymentSet';
import { resolveUnpInfo } from '@/api/api';
import { mapGetters } from 'vuex';
import {
  forceUpper,
  copyToClipboard,
  russianDate,
  updateObjectOnlyIfContainedInReference,
  randomArrayItem,
  numberPad,
  randomChars,
  randomFromRange,
  randomDate,
  toPageTop,
  freshClient,
  vuelidateExtractErrors,
  describeGaiInfo,
  isNewVehicle,
} from '@/lib/funcs';
import {
  freshPermission,
  freshPayment,
  calcValidTo,
  clipboardSupported,
  prepare4Clipboard,
  findRefNameById,
  checkPermissionValid,
  cnEquals,
  checkRecentPermissions,
} from './permHelpers';
import { patterns, payFormsTax, formsOfOwnership } from '@/lib/consts';
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import ClientComponent from '@/controls/ClientComponent';
import PermissionPeriod from './PermissionPeriod';
import PermissionActExtraInfo from '@/components/perms/PermissionActExtraInfo'
import SelectKdr from '@/components/SelectKdr';
import { modifiable, askThenDo } from '@/lib/mixins';
import { required, requiredIf, minValue, maxLength } from 'vuelidate/lib/validators';
import { regex, clientValidation, paymentsValidation, trueValidator } from '@/lib/validators';
import RomeDatepicker from '@/controls/RomeDatepicker';
import { getTariff, isUserInRole } from '@/store/store';
import { sign } from '@/lib/sign';
import moment from 'moment';

export default {
  data() {
    return {
      errors: [],
      payFormsTax,
      targetPayment: {},
      allowedServices: null,
      test: { testIn: 'txt' },
      showSelectKdr: false,
      periodCalcMessage: null,
      fetching: false,
      posting: false,
      initialErr: false,
      m: freshPermission(),
      m_: null,
      objectName: 'Разрешение',
      patterns,
      blockUI: false,
      withoutVin: false,
      loading: true,
      selectedGaiInfo: null,
      showedGaiInfo: null,
    };
  },
  validations() {
    let m = this.m;
    let vm = this;
    return {
      m: {
        cn: { required, pattern: regex(patterns.cn) },
        regNum1: { required, pattern: regex(patterns.combinedRN) },
        regNum2: { pattern: regex(patterns.combinedRN) },
        vin: {
          req: requiredIf((nm) => !nm.fromGai),
          pattern: this.m.fromGai ? trueValidator : regex(patterns.vinStrict),
        },
        mId: { required },
        mass: { required, mn: minValue(1) },
        cId: { required },
        y: { required },
        applId: { required },
        hold: { n: { req: requiredIf((nm) => !this.m.transfer1) } },
        dl: {
          req: requiredIf((nm) => {
            let lt = 0;
            if (nm.ow) lt = nm.ow.t;
            // ВУ не требуется для переносов и ИП и ЮЛ:
            return !(vm.anyTransfer || [2, 1].includes(lt));
          }),
          maxlen: maxLength(18),
        },
        ow: clientValidation(true),
        cust: m.transfer1 ? { t: {}, n: {}, u: {} } : clientValidation(),
        dc: m.newAuto ? {} : { pattern: regex(patterns.dc) },
        dcDate: { req: requiredIf((nm) => !m.newAuto) },
        dcDSId: { req: requiredIf((nm) => !m.newAuto) },
        pDSId: { required },
        pDate: { required },
        pPeriod: {
          req: requiredIf((nm) => !m.transfer1),
          val: (value, ctx) => {
            return [6, 12, 24, 36].includes(value)
          }
        },
        validTo: { required },
        tdrId: { req: requiredIf((nm) => vm.showTaxInterface) },
        p2: this.showTaxPayments ? paymentsValidation() : {},
        p1: this.m.transfer1 ? {} : paymentsValidation(),
      },
    };
  },
  computed: {
    ...mapGetters([
      'debugMode',
      'userSettings',
      'gmodels',
      'ds',
      'cats',
      'applyings',
      'areas',
      'regions',
      'colors',
      'userInfo',
      'taxdiscounts',
    ]),
    checkRecentPermissionsEnabled() {
      let m = this.m;
      // Если не заполнен год выпуска ТС или дата разрешения:
      if (!m.y || isNaN(m.y) || !m.pDate) return false;
      if (!isNewVehicle(m.y, m.pDate)) return false;
      return !(this.withoutVin || this.$v.m.vin.$invalid);
    },
    isSaved() {
      return this.m.guid ? 'редактирование' : 'создание';
    },
    showTaxPayments() {
      let m = this.m;
      if (!this.showTaxInterface) return false;
      let taxDiscount = this.taxdiscounts.find((el) => el.id == m.tdrId);
      return !taxDiscount || taxDiscount.percent != 100;
    },
    showTaxInterface() {
      return !this.anyTransfer && moment(this.m.pDate) < moment('2021-01-01');
    },
    /**
     * признак того, что разрешение является переносом любого типа.
     */
    anyTransfer() {
      return this.m.transferType > 0;
    },
    /**
     * признак того, что разрешение является переносом 1-го типа.
     */
    transfer1() {
      return this.m.transferType == 1;
    },
    /**
     * признак того, что разрешение является переносом 2-го типа.
     */
    transfer2() {
      return this.m.transferType == 2;
    },
    /**
     * признак того, что разрешение является переносом 3го типа.
     */
    transfer3() {
      return this.m.transferType == 3;
    },
    disableGaiControls() {
      return this.m.fromGai != 0 && !isUserInRole('admin', 'perm_moderator');
    },
    allowPrintPermission2021() {
      let vm = this;
      let result =
        !vm.initialErr && !vm.$v.m.$invalid && moment(vm.m.pDate) >= moment('2021-01-01') && _isEqual(vm.m, vm.m_) && (!vm.anyTransfer || vm.transfer2);
      return result;
    },
    allowPrintPermissionAct() {
      let vm = this;
      let result = !vm.initialErr && !vm.$v.m.$invalid && vm.m.cust && vm.m.cust.t != 0 && _isEqual(vm.m, vm.m_);
      return result;
    },
    showedGaiDescription() {
      let i = this.showedGaiInfo;
      if (!i) return null;
      return describeGaiInfo(i.originalData);
    },
  },
  components: {
    VueSingleSelect,
    ClientComponent,
    PaymentSet,
    SelectKdr,
    RomeDatepicker,
    PermissionPeriod
  },
  methods: {
    checkRecentPermissions() {
      checkRecentPermissions(this, this.m.pDate, this.m.vin)
    },
    cancel() {
      if (this.anyTransfer) {
        this.$router.push('/perms/ownTas3');
      } else {
        this.$router.go(-1);
      }
    },
    leaveForm() {
      // Раньше закрывали разрешение после сохранения, теперь не будем
      // let vm = this;
      // if (vm.$route.params.applId) {
      //   vm.$router.push({ path: '/issued/perms' });
      // } else {
      //   vm.$router.go(-1);
      // }
    },
    // методы из библиотеки:
    forceUpper,
    russianDate,
    errFields() {
      let result = [];
      vuelidateExtractErrors('', this.$v, result);
      this.errors = result;
    },
    printAct() {
      let vm = this;
      vm.$dlg.modal(PermissionActExtraInfo, {
        params: { unp: vm.m.cust.u, name: vm.m.cust.n },
        width: 600,
        height: 250,
        title: 'Ввод информации для печати акта',
        callback: (x) => {
          if (!x) return;
          let dsNumber = vm.userSettings.curDsNumber || '             ';
          window.open(
            `s/docs/pdf2/permAct/?guid=${vm.m.guid}&address=${encodeURIComponent(
              x.address
            )}&dsNumber=${encodeURIComponent(dsNumber)}&ssId=${this.getSubserviceId()}`,
            '_blank'
          );
        },
      });
    },
    printTaNotice() {
      if (!this.m.guid) return;
      window.open(`s/docs/pdf2/taNotice/${this.m.guid}`, '_blank');
    },
    printPerm2021() {
      if (!this.m.guid) return;
      window.open(
        `s/docs/pdf2/perm2021/${this.m.guid}?topOffset=${this.userSettings.topOffset}&leftOffset=${
          this.userSettings.leftOffset
        }`,
        '_blank'
      );
    },
    issueAct() {
      let vm = this;
      let m = vm.m;
      if (m.actId) {
        vm.$dlg.alert('Акт на ' + (m.transfer1 ? 'этот перенос' : 'это разрешение') + ' уже выдан', {
          messageType: 'warning',
        });
        return;
      }
      if (!(m.cust && m.cust.t != 0 && m.cust.u >= 100000000 && m.cust.u <= 999999999)) {
        vm.$dlg.alert('Заказчик является физическим лицом либо неправильно указан УНП', { messageType: 'warning' });
      }
      // vm.$dlg.modal(IssueAct, {
      //   params: {
      //     unp: m.cust.u,
      //     serviceId: m.transfer1 ? 11 : 4,
      //   },
      //   width: 700,
      //   height: 300,
      //   title:
      //     "Выставление акта к " +
      //     (m.transfer1 ? "переносу " : "разрешению ") +
      //     m.permNumber,
      //   callback: vm.issueActComplete
      // })
    },

    issueActComplete(actId) {
      let vm = this;
      vm.showIssueAct = false;
      vm.m.actId = actId;
      vm.m_.actId = actId;
    },
    // прочие методы:
    withoutVinChanged() {
      if (this.withoutVin) {
        this.m.vin = 'Б/Н';
      } else {
        this.m.vin = '';
      }
    },
    fillDummyData() {
      var m = this.m;
      m.pDSId = this.userSettings.curDsId;
      m.dcDSId = this.userSettings.curDsId;
      m.mass = randomFromRange(500, 1500);
      m.applId = randomArrayItem(this.applyings).id;
      m.dc = randomChars(2) + ' ' + Math.floor(Math.random() * 1000000);
      m.dl =
        Math.floor(Math.random() * 89) +
        10 +
        ' ' +
        (Math.floor(Math.random() * 89) + 10) +
        ' ' +
        Math.floor(Math.random() * 1000000);
      m.pDate = randomDate();
      m.pPeriod = 12;
      this.periodChanged();
      m.cn = 'ACA056257';
      // m.cn = "MAA0348092"
      m.dcDate = moment(m.pDate)
        .add(-15, 'days')
        .format('YYYY-MM-DD');
      m.mId = randomArrayItem(this.gmodels).id;
      m.cId = randomArrayItem(this.cats).id;
      m.tdrId = randomArrayItem(this.taxdiscounts).id;
      m.y = randomFromRange(1990, 2018);
      m.regNum1 = randomChars(2) + randomFromRange(1000, 9999) + '-' + randomFromRange(1, 7);

      m.aId = randomArrayItem(this.areas).id;
      m.hold.n = randomChars(10);
      m.cust.n = randomChars(10);
      m.ow.n = randomChars(30);
      m.ow.u = randomFromRange(100000000, 999999999);

      m.vin = randomChars(17);
      if (m.p1.payments) {
        m.p1.payments.splice(0, 1000);
        m.p1.payments.push({
          t: 2,
          s: 6.9,
          docDate: '2018-01-02', // randomDate(),
          docNum: '6520', // '' + Math.floor(Math.random() * 800000000 + 100000000),
        });
        m.p1.payments.push({
          t: 2,
          s: 6.9,
          docDate: '2017-12-20',
          docNum: '41091',
        });
      }

      m.p2.payments.splice(0, 1000);
      m.p2.payments.push({
        t: 2,
        s: 100,
        docDate: randomDate(),
        docNum: '' + Math.floor(Math.random() * 800000000 + 100000000),
      });
      m.p2.payments.push({
        t: 3,
        s: 200,
        payId: Math.floor(Math.random() * 10000000000 + 1000000000),
      });
    },
    excelClipboard() {
      let vm = this;
      if (!clipboardSupported()) {
        vm.$dlg.alert('Копирование в буфер обмена не поддерживается вашим браузером', {
          messageType: 'error',
        });
        return;
      }
      try {
        var m = vm.m;
        var s = 0;
        var p = m.p2 && m.p2.payments ? m.p2.payments : null;
        var f = -2;
        var inf = '';
        if (p) {
          p.forEach(function(e) {
            s += e.s;
            var tN = Number(e.t);
            switch (tN) {
              case 1:
                inf = inf + ', наличные';
                break;
              case 2:
                inf = inf + ', ПП №' + e.docNum + ' от ' + russianDate(e.docDate);
                break;
              case 3:
                inf = inf + ', ЕРИП № ' + e.payId;
                break;
              case 4:
                inf = inf + ', акт сверки от ' + russianDate(e.docDate);
                break;
              case 5:
                inf = inf + ', o-plati № ' + e.payId;
                break;
              case 6:
                inf = inf + ', квитанция №' + e.docNum + ' от ' + russianDate(e.docDate);
                break;
              case 7:
                inf = inf + ', картой, № ' + e.payId;
                break;
            }
            if (f === -2) {
              f = tN;
            } else if (f !== tN) {
              f = -1;
            }
          });
          if (f === -2) {
            f = 'нет';
          } else if (f === -1) {
            f = 'разная';
          } else {
            f = payFormsTax.find(function(e) {
              return e.id === f;
            }).name;
          }
        }
        var _owner = m.ow ? m.ow : freshClient();
        var _customer = m.cust ? m.cust : freshClient();
        var _2darray = [
          m.cn,
          m.regNum1,
          m.vin,
          findRefNameById(vm.gmodels, m.mId),
          m.mass,
          findRefNameById(vm.cats, m.cId),
          m.y,
          m.hold ? m.hold.n : '',
          m.dl,
          formsOfOwnership[Number(_owner.t)].name,
          _owner.n,
          _owner.u,
          m.newAuto ? 'да' : 'нет',
          m.dc,
          m.dcDate ? russianDate(m.dcDate) : '',
          findRefNameById(vm.ds, m.dcDSId),
          m.permNumber,
          findRefNameById(vm.ds, m.pDSId),
          russianDate(m.pDate),
          m.pPeriod,
          m.tdrId == 1 || m.tdrId == 5 ? 'да' : 'нет',
          f,
          s,
          inf === '' ? 'нет данных' : inf.substring(2),
          vm.userInfo.name,
          _customer.n,
          _customer.u,
          findRefNameById(vm.applyings, m.applId),
          findRefNameById(vm.areas, m.aId),
          russianDate(m.validTo),
        ];
        copyToClipboard(prepare4Clipboard(_2darray));
        vm.$dlg.toast('Информация о разрешении скопирована в буфер обмена', { messageType: 'success', closeTime: 3 });
      } catch (e) {
        vm.$dlg.alert('Ошибка при копировании информации в буфер обмена: ' + e, {
          messageType: 'error',
        });
      }
    },
    checkPaymentsAndSave() {
      let vm = this;
      let ps = vm.m.p1;
      let msgs = checkPermissionValid(vm.m, this.taxdiscounts);
      if (msgs.length) {
        this.$showError('Ошибка', { errCode: 1, message: msgs.join('<br>') });
        return;
      }
      let payments = ((ps && ps.payments) || []).filter((p) => p.t == 2);
      if (payments.length == 0) {
        vm.confirmPeriodAndSave();
        return;
      }
      ajax
        .findUsedPaymentOrders4Permission({ unp: vm.m.cust.u, ...(vm.m.guid ? { guid: vm.m.guid } : null) }, payments)
        .then((r) => {
          let result = r.data.content;
          if (result.length == 0) {
            vm.confirmPeriodAndSave();
            return;
          }
          let message = 'Дублирующие номера платежных поручений:\n';
          for (let it of result) {
            message = `${message}\n№ <b>${it.doc_num}</b> от <b>${russianDate(it.doc_date)}</b> (Разрешение ${
              it.perm_number
            }, ${it.name})`;
          }
          message =
            message +
            '\n\nДля сохранения разрешения (если уверены в отсутствии мошенничества), нажмите "ДА". Либо нажмите "НЕТ" и перепроверьте введенные данные';
          vm.askThenDo(message.replace(/\n/g, '<br>'), vm.confirmPeriodAndSave, null, 600, 300);
        })
        .catch((err) => {
          vm.$log.error('Ошибка проверки дублирования платежей', err);
          // Даже если при проверке платежек возникла проблема (типа слишком частых запросов), позволим сохранить разрешение
          vm.confirmPeriodAndSave();
        });
    },
    confirmPeriodAndSave() {
      let vm = this;
      if (vm.anyTransfer) {
        vm.save();
      } else {
        let m = this.m;
        let applying = findRefNameById(vm.applyings, m.applId);
        let cat = findRefNameById(vm.cats, m.cId);
        let text = `Вы уверены, что корректно установили срок действия разрешения?\n\nСрок: <b>${
          m.pPeriod
        }</b> месяцев\nГод выпуска: <b>${m.y}</b>\nКатегория: <b>${cat}</b>\nПрименение: <b>${applying}</b>`;
        vm.askThenDo(text.replace(/\n/g, '<br>'), vm.save, null, 400, 260);
      }
    },
    save() {
      let vm = this;
      let m = vm.m;
      if (vm.blockUI) {
        vm.$log.debug('Предотвращаю повторную отправку, пока не завершилась первая');
        return;
      }
      if (_isEqual(m, vm.m_)) {
        vm.leaveForm();
        return;
      }
      // // Хитровыделанный алгоритм простановки времени разрешения
      // if (
      //   !m.guid ||
      //   m.pDate.getYear() != vm.m_.pDate.getYear() ||
      //   m.pDate.getMonth() != vm.m_.pDate.getMonth() ||
      //   m.pDate.getDay() != vm.m_.pDate.getDay()
      // ) {
      //   // Это новое разрешение/перенос или же дата в нем (без учета времени) поменялась
      //   // подставляем текущее время
      //   let now = new Date()
      //   m.pDate.setHours(now.getHours())
      //   m.pDate.setMinutes(now.getMinutes())
      //   m.pDate.setSeconds(now.getSeconds())
      // }

      vm.blockUI = true;
      sign(vm.m)
        .then((d) =>
          ajax.savePermission({ applicationId: vm.$route.params.applId, transferedGuid: vm.transferedGuid }, d)
        )
        .then((r) => {
          vm.m.guid = r.data.content.guid;
          vm.m_ = _cloneDeep(vm.m);
          vm.$dlg.toast('Сохранено', { messageType: 'success', closeTime: 3 });
          vm.leaveForm();
        })
        .catch((err) => {
          vm.$showError('Ошибка сохранения', err);
        })
        .finally(() => {
          vm.blockUI = false;
        });
    },
    cnChanged() {
      let gi = this.selectedGaiInfo;
      if (!gi) {
        this.showedGaiIinfo = null;
        return;
      }
      // проверить, что новый сертификат совпадает с выбранным из ГАИ
      let eq = cnEquals(this.m.cn, gi.originalData.DOC_SERY, gi.originalData.DOC_NUM);
      this.showedGaiInfo = eq ? gi : null;
    },
    applyKdr(gaiAndKdrInfo) {
      let vm = this;
      vm.showSelectKdr = false;
      let k = gaiAndKdrInfo[1];
      let g = gaiAndKdrInfo[0];
      // приоритет имеет инфа из ГАИ, поэтому первым заполняем данные КДР
      let m = vm.m;
      if (k !== null) {
        m.kdrId = k.guid;
        m.y = k.year;
        m.dcDate = moment(k.tiDate).format();
        m.dc = !k.dcNumber ? null : (k.dcSeries || '') + numberPad(k.dcNumber, 7);
        m.dl = k.dl;
        updateObjectOnlyIfContainedInReference(vm.cats, k.catId, m, 'cId');
        updateObjectOnlyIfContainedInReference(vm.gmodels, k.modelId, m, 'mId');
        m.ow.n = k.owner;
        m.ow.t = k.ownerType;
        m.ow.u = k.ownerUnp === 0 ? '' : k.ownerUnp;
        m.hold.n = k.holder;
        m.regNum1 = k.regNumber;
        vm.setVin(k.vin);
        m.dcDSId = k.dsId;
        updateObjectOnlyIfContainedInReference(vm.applyings, k.applId, m, 'applId');
        if (m.ow.t != 0 && !vm.$v.m.ow.u.$invalid) {
          resolveUnpInfo(m.ow.u).then(({ name }) => {
            m.ow.n = name;
          });
        }
        if (m.cust.t != 0 && !vm.$v.m.cust.u.$invalid) {
          resolveUnpInfo(m.cust.u).then(({ name }) => {
            m.cust.n = name;
          });
        }
      }
      if (g !== null) {
        m.mass = g.massMax;
        m.gaiId = g.regId;
        updateObjectOnlyIfContainedInReference(vm.gmodels, g.modelId, m, 'mId');
        m.y = g.y;
        m.regNum1 = g.rn;
        m.regNum2 = g.rnExtra;
        vm.setVin(g.vin ? g.vin : g.chassis);
        m.fromGai = 1;
        m.cId = g.cId;
        vm.selectedGaiInfo = g;
        vm.showedGaiInfo = g;
      } else {
        vm.showedGaiInfo = null;
      }
    },
    setVin(newVin) {
      let vm = this;
      if (newVin) {
        vm.m.vin = newVin;
        vm.withoutVin = false;
      } else {
        vm.m.vin = 'Б/Н';
        vm.withoutVin = true;
      }
    },
    /**
     * В зависимости от флагов и форм собственности собственника и заказчика определить номер подуслуги (для расчёта тарифа)
     */
    getSubserviceId() {
      let m = this.m;
      switch (m.transferType) {
        case 2: return 1300;
        case 3: return 1600;
        default: return (m.ow.t == 0 && m.cust.t == 0) ? 101 : 100;
      }
    },
    /**
     * Пересчитать сумму за услугу и заполнить сумму платежа (если он только один и его не изменяли вручную)
     */
    recalcSum() {
      let m = this.m;
      if (m.p1.payments.length != 1 || this.$v.m.p1.payments.$each[0].s.$dirty) {
        return;
      }
      if (!m.pDate || m.ow.t != 0 || m.cust.t != 0) {
        m.p1.payments[0].s = null;
      } else {
        m.pS = getTariff(this.getSubserviceId(), m.pDate).total;
        m.p1.payments[0].s = m.pS;
      }
    },
    /**
     * пересчитать срок действия.
     */
    recalcValidTo() {
      if (this.anyTransfer) {
        return;
      }
      let m = this.m;
      if (m.pPeriod) {
        m.validTo = calcValidTo(m.pDate, m.pPeriod);
      }
    },
    dateChanged() {
      this.recalcValidTo();
      this.recalcSum();
    },
    periodChanged() {
      console.log('period changed', this.m.pPeriod)
      this.recalcValidTo();
    },
    /**
     * @param {Boolean} forTax true, если добавляется тариф на госпошлину
     */
    addPayment(paymentSet, forTax) {
      let p = freshPayment();
      if (!forTax && paymentSet.payments.length == 0 && this.m.pDate) {
        p.s = getTariff(this.getSubserviceId(), this.m.pDate).total;
      }
      paymentSet.payments.push(p);
    },
    /**
     * Получить список услуг, разрешенных для выбора платежа, в зависимости от условий.
     * @param {Boolean} forTax true, если нужно получить список "услуг", соответствующих госпошлине
     */
    getAllowedServices(forTax) {
      let result;
      if (forTax) {
        result = [2, 3];
      } else {
        switch (this.m.transferType) {
          case 2:
            result = [13];
            break;
          case 3:
            result = [13, 16];
            break;
          default:
            result = [4, 5];
            break;
        }
      }
      return result;
    },
  },
  mounted() {
    toPageTop();
    let vm = this;
    vm.m_ = _cloneDeep(vm.m);
    let guid = vm.$route.params.guid;
    if (guid) {
      let transferType = vm.$route.params.transferType;
      vm.blockUI = true;
      ajax
        .getPermission({ guid, tr: transferType > 0 })
        .then(
          (r) => {
            vm.m = r.data.content;
            var m = vm.m;
            if (transferType == 2 || transferType == 3) {
              // хотим не редактировать разрешение, а создать перенос информации на его основе
              m.transferType = transferType;
              m.regNum1 = '';
              m.regNum2 = '';
              m.aId = null;
              m.pDate = moment().format();
              let tariff = getTariff(vm.getSubserviceId(), m.pDate).total;
              vm.transferedGuid = m.guid;
              if (transferType == 2) {
                m.bId = vm.$route.params.bId;
              }
              m.permNumber = vm.$route.params.blankNum;
              m.guid = null;
              switch (transferType) {
                case 2:
                  vm.objectName = 'перенос';
                  break;
                case 3:
                  vm.objectName = 'внесение изменений';
                  break;
              }
              m.p1 = {
                payments: [{ ...freshPayment(), s: tariff }],
              };
              m.p2 = { payments: [] };
              m.pPeriod = 0;
              m.cn = vm.$route.params.cn;
              m.fromGai = 0;
              m.pDSId = vm.userSettings.curDsId;
              m.pS = tariff;
              m.tS = 0;
              m.dl = null;
              m.cust = freshClient(0);
              m.hold = freshClient(0);
              m.ow = freshClient(0);
              vm.m_ = _cloneDeep(vm.m);
              // vm.showSelectKdr = true
            } else {
              vm.m_ = _cloneDeep(vm.m);
              vm.objectName = vm.m.transferType ? 'Перенос' : 'Разрешение';
            }
          },
          (err) => {
            vm.initialErr = true;
            vm.$showError('Ошибка загрузки разрешения (переноса)', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
          vm.loading = false;
        });
    } else {
      // новое разрешение
      vm.objectName = 'Разрешение';
      var m = vm.m;
      var bl = vm.$route.params.blank;
      if (bl) {
        m.bId = bl.id;
        m.permNumber = bl.s + (1e9 + bl.n + '').slice(-7);
      } else {
        vm.$router.push({ path: '/blanks' });
      }
      m.dcDSId = vm.userSettings.curDsId;
      m.pDSId = vm.userSettings.curDsId;
      m.tdrId = 5;
      m.dc = vm.userSettings.dcSer ? String(vm.userSettings.dcSer).toUpperCase() : '';
      vm.m_ = _cloneDeep(vm.m);
      vm.loading = false;
    }
  },
  mixins: [modifiable, askThenDo],
  watch: {
    'm.ow.t'(newValue) {
      if (!this.loading) this.recalcSum();
    },
    'm.cust.t'() {
      if (!this.loading) this.recalcSum();
    },
  }
};
</script>
