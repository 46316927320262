<template>
  <div>
    <div>
      <div class="col-sm-2 text-center">Форма</div>
      <div class="col-sm-2 text-center">Сумма</div>
      <div class="col-sm-6 text-center" v-show="local.t==1">&nbsp;</div>
      <div class="col-sm-3 text-center" v-show="local.t==4">&nbsp;</div>
      <div class="col-sm-3 text-center" v-show="local.t==2||local.t==6">№ документа</div>
      <div class="col-sm-3 text-center" v-show="local.t==2||local.t==4||local.t==6">Дата документа</div>
      <div class="col-sm-6 text-center" v-show="local.t==3||local.t==5">№ платежа</div>
      <div class="col-sm-6 text-center" v-show="local.t==7">№ транзакции</div>
      <div class="col-sm-2 text-center">&nbsp;</div>
    </div>
    <div>
      <!-- форма оплаты: -->
      <div class="col-sm-2 no-pad">
        <select
          class="form-control"
          v-model="local.t"
          @change="updateNumeric('t', $event.target.value)"
        >
          <option v-for="f in payForms" :key="f.id" :value="f.id" :title="f.description">{{f.name}}</option>
        </select>
      </div>
      <!-- сумма: -->
      <div class="col-sm-2 no-pad">
        <input
          type="number"
          :value="local.s"
          min="0.01"
          step="0.01"
          onwheel.passive="return !1;"
          class="form-control"
          @input="updateSum($event.target.value)"
          :class="{[errorClass]:v.s.$invalid}"
        />
      </div>

      <!-- наличные: -->
      <div class="col-sm-6 no-pad" v-show="local.t==1">&nbsp;</div>

      <!-- Платёжное поручение и квитанция: -->
      <div class="col-sm-3 no-pad" v-show="local.t==2||local.t==6">
        <input
          type="text"
          class="form-control"
          :value="local.docNum"
          placeholder="№ (макс. 11 знаков)"
          @input="update('docNum', $event.target.value)"
          :class="{[errorClass]:v.docNum.$invalid}"
        />
      </div>
      <div class="col-sm-3 no-pad" v-show="local.t==4">&nbsp;</div>
      <div class="col-sm-3 no-pad" v-show="local.t==2||local.t==4||local.t==6">
        <rome-datepicker
          :options="{time:false,inputFormat: 'DD.MM.YYYY'}"
          modelFormat="YYYY-MM-DD"
          :value="local.docDate"
          class="form-control"
          @input="update('docDate', $event)"
          placeholder="Дата"
          :class="{[errorClass]:v.docDate.$invalid}"
        />
      </div>

      <!-- ЕРИП, банковская карта и o-plati: -->
      <div class="col-sm-6 no-pad" v-show="local.t==3||local.t==5||local.t==7">
        <input
          type="number"
          :value="local.payId"
          min="1"
          step="1"
          onwheel.passive="return !1"
          class="form-control"
          @input="updateNumeric('payId', $event.target.value)"
          :class="{[errorClass]:v.payId.$invalid}"
        />
      </div>

      <!-- действия: -->
      <div class="col-sm-2 no-pad">
        <div class="btn-group">
          <button
            type="button"
            class="btn"
            title="Искать"
            @click="$emit('search', { payment: value, payType: local.t })"
            :disabled="local.t!=3 && local.t!=7"
          >
            <i class="fa fa-search"></i>
          </button>
          <button type="button" class="btn btn" title="Удалить" @click="del">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { freshPayment } from '@/lib/funcs';
import ConfirmDialogVue from '@/controls/ConfirmDialog.vue';
import RomeDatepicker from '@/controls/RomeDatepicker';
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    v: {
      type: Object,
    },
    errorClass: {
      type: String,
    },
    payForms: {
      type: Array,
      required: true,
    },
  },
  methods: {
    del() {
      let vm = this;
      vm.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: 'Будет удален платеж. Вы уверены?',
          buttons: 6,
          focusButton: 2,
        },
        width: 400,
        height: 180,
        title: 'Внимание',
        callback: (b) => b === 2 && vm.$emit('del'),
      });
    },
    update(key, value) {
      this.$emit('input', { ...this.local, [key]: value });
    },
    updateSum(value) {
      this.v.s.$touch();
      this.updateNumeric('s', value);
    },
    updateNumeric(key, value) {
      this.$emit('input', { ...this.local, [key]: Number(value) });
    },
  },
  computed: {
    local() {
      return this.value ? this.value : freshPayment();
    },
  },
  components: { RomeDatepicker },
};
</script>
