<template>
  <div>
    <h3>Админское</h3>
    <p>
      <a href="s/m/cacheInfo" target="_blank">Информация о кэшах</a>
    </p>
    <p>
      <a href="s/m/clearCaches" target="_blank" title="Перечитать настройки, очистить кэши, загрузить филиалы и перечитать даты запрета изменений"
        >Перечитать настройки, очистить кэши <span style="text-decoration: underline">?</span></a
      >
    </p>
    <p>
      <input type="button" value="create representation" @click="createRepresentation" />
    </p>

    <br />

    <div class="form-group row">
      <div class="col-sm-6" v-for="bin in binaries" :key="bin.id">
        <binary-viewer :url="bin.url" :mime-type="bin.mimeType" />
      </div>
    </div>
    <button type="button" @click="showImagePopup">image popup</button>
    <!-- <embed ref="embedPdf" src="http://localhost:4445/engineer_test/s/oais/binary/12" width="50%" class="img-shrinked" type="application/pdf" /> -->
    <div class="form-group">
      <label class="control-label col-sm-4">Пароль отладочного режима на текущий час</label>
      <div class="input-group col-sm-3">
        <input class="form-control" type="text" readonly :value="debugPassword" />
        <span class="input-group-btn">
          <button
            class="btn btn-default"
            type="button"
            title="Посчитать пароль для открывания отладочного режима"
            @click.prevent.stop="calcDebugPassword"
          >
            ...
          </button>
        </span>
      </div>
    </div>

    <button class="btn btn-lg btn-primary btn-block" @click="testAxios">test axios</button>

    <form>
      <fieldset :disabled="false">
        <div class="form-group row">
          <label class="control-label col-sm-4">Серия и номер ДК</label>
          <div class="col-sm-7 no-pad">
            <input type="text" class="form-control" v-model="dc" @input="forceUpper($event, m, 'dc')" />
          </div>
        </div>

        <div class="form-group row">
          <label class="control-label col-sm-4">Дата выдачи ДК </label>
          <div class="col-sm-7 no-pad" style="margin-bottom: 0.5em">
            <rome-datepicker v-model="dcDate" class="form-control" :options="{ time: false, inputFormat: 'DD.MM.YYYY' }" modelFormat="YYYY-MM-DD" />
          </div>
        </div>
        <div class="form-group row">
          <label class="control-label col-sm-4">бланк</label>
          <div class="col-sm-7 input-group">
            <vue-single-select v-model="blankId" :options="blanks" ref="blanks" :filter-by="filterKeyboardAgnostic" />
          </div>
        </div>
      </fieldset>
    </form>
    <button @click="showRefs">show refs</button>
    <br />
    <input type="text" readonly @click="toggleEdsError" :value="(edsError ? '' : 'Не ') + 'вносить ошибки в ЭЦП'" />
    <br />
    <input type="number" v-model.number="unp" />
    <input type="checkbox" v-model="ignoreBranches" />
    <button @click="unpPromises()">unpInfo with promises</button>

    <client-component v-model="doc.client" :v="$v.doc.client" />
    <input type="button" value="set branch" @click="doc.client.b = 9004" />
    <input type="button" value="reset branch" @click="doc.client.b = null" />

    <input type="button" value="set client" @click="doc.client = { n: 'Таск', u: 100003006, t: 1, b: 9003 }" />
    <input type="button" value="reset client" @click="doc.client = freshClient()" />

    <button @click="getChangelog()">changelog</button>
    <br />
    <textarea cols="130" rows="100" v-model="changelog" readonly />

    <!-- <BlockUI :message="msg" v-if="msg">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>-->

    <!-- <vue-datepicker :value="state.date" format="dd.MM.yyyy" :typeable="true"></vue-datepicker> -->

    <button @click="model = 7009317">Set model to 7009317</button>
    <button @click="model = -18">Set model to -18 (non-existent)</button>
    <button @click="models = gmodels">set default option list</button>
    <button @click="models = []">set empty option list</button>

    <!-- <a11y-dialog id="app-dialog" :app-root="null" @dialog-ref="assignDialogRef">
      <h1 slot="title">Your dialog title</h1>
      <div>
        <p>Your content</p>
      </div>
    </a11y-dialog>-->
    <select-kdr v-if="showMyModal" @close="showMyModal = false" width="1000"></select-kdr>
    <button type="button" @click="openDialog">Open dialog</button>
    <button @click="showMyModal = true">show my modal</button>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <p>{{ state }}</p>
    <p>{{ model }}</p>
    <p>
      то что на поле match
      <svg style="width: 40; height: 40" viewBox="0 0 512 512">
        <path
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
        />
      </svg>
    </p>

    <p>
      то что на поле search при закрытом списке
      <svg style="width: 40; height: 40" viewBox="0 0 448 512">
        <!-- стрелка вниз -->
        <path
          d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
        />
      </svg>
    </p>
    <p>
      то что на поле search при открытом списке
      <svg style="width: 40; height: 40" viewBox="0 0 448 512">
        <!-- Стрелка вниз -->
        <path
          d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
        />
      </svg>
    </p>
  </div>
</template>

<script>
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import SelectKdr from '@/components/SelectKdr';
import { mapGetters } from 'vuex';
import { b64EncodeUnicode, freshClient, filterKeyboardAgnostic } from '@/lib/funcs'
import ClientComponent from '@/controls/ClientComponent';
import { clientValidation } from '@/lib/validators';
import { resolveUnpInfo } from '@/api/api';
import { currentDebugPassword } from '../lib/funcs';
import RomeDatepicker from '@/controls/RomeDatepicker';
import oaisApi from '@/api/oais'
import BinaryViewer from '@/components/BinaryViewer';
import BinaryViewerWrapper from '@/components/BinaryViewerWrapper'
import { signMultiple } from '@/lib/sign';
import { ENVELOPE_CONTENT_TYPES } from '@/lib/consts';
import { testOaisPermission } from './test.js';
import userApi from '@/api/user'
import { calculateDimensions } from '@/components/perms/edit/permHelpers'

export default {
  name: 'AppTest',
  components: { VueSingleSelect, SelectKdr, ClientComponent, RomeDatepicker, BinaryViewer },
  created() {
    // this.$log.debug(this.gmodels)
    let vm = this;
    setTimeout(() => {
      vm.msg = null;
    }, 3000);

    vm.$log.debug('window.innerHeight ', window.innerHeight);
    vm.$log.debug('window.innerWidth ', window.innerWidth);
    vm.models = vm.gmodels;
  },
  computed: {
    ...mapGetters(['gmodels', 'edsError']),
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyPress);
  },
  destroyed() {
    document.removeEventListener('keydown', this.onKeyPress);
  },
  methods: {
    showImagePopup() {
      let vm = this
      let b = vm.binaries[0]
      let dimensions = calculateDimensions()
      console.log('dimensions', dimensions)
      vm.$dlg.modal(BinaryViewerWrapper, {
        params: { ...b, buttons: 9 },
        width: dimensions.width,
        height: dimensions.height,
        title: 'Подтверждение',
        callback: function(b) {
          console.log('modal callback function', b)
        },
      });
    },
    signAndSave(payload, mimeType, extension) {
      let vm = this
      signMultiple(payload, true, '6BEB902F 8D9C0A6D 126802A0 99762D45 D1415A16')
        .then((signedData) => {
          console.log('before save:');
          let applicationNumber = 4074;
          let payload = {
            mimeType,
            applicationNumber,
            filename: `${applicationNumber}.${extension}`,
            permission: { ...signedData[0], t: ENVELOPE_CONTENT_TYPES.JSON },
            representation: { ...signedData[1], t: ENVELOPE_CONTENT_TYPES.IMAGE },
          };
          console.log(payload);
          // return oaisApi.fulfill(payload);
        })
        .then((r) => {
          vm.$dlg.toast('Сохранено', { messageType: 'success', closeTime: 3 });
        })
        .catch((e) => {
          console.log(e);
          this.$showError('Ошибка', e);
        });
    },
    createRepresentation() {
      let vm = this
      let permissionData = {
        permission: JSON.stringify(this.permission),
        mimeType: 'image/webp',
      }

      oaisApi.createRepresentation(permissionData)
        .then((resp) => {
          // show result:
          let mimeType = resp.data.content.mimeType
          let extension = resp.data.content.extension
          let dimensions = calculateDimensions()
          vm.$dlg.modal(BinaryViewerWrapper, {
            params: {
              url: `data:${mimeType};base64,${resp.data.content.blob}`,
              mimeType,
              buttons: 9
            },
            width: dimensions.width,
            height: dimensions.height,
            title: 'Предпросмотр',
            callback: (b) => {
              if (b !== 1) return
              vm.signAndSave([b64EncodeUnicode(resp.data.content.permission), resp.data.content.blob], mimeType, extension)
            },
          })
        })
    },
    testAxios() {
      userApi.getUserInfo()
        .then((resp) => {
          this.$log.info('then', resp)
        })
        .catch((err) => {
          this.$log.error('catch', err, err.status, err.errCode)
        })
        .finally(() => {
          this.$log.info('finally')
        })

      // ajax.testAxios()
      //   .then((r) => {
      //     console.log('then');
      //     console.log(r);
      //   })
      //   .catch((e) => {
      //     console.log('catch');
      //     console.log(e);
      //     console.log(`err.name: ${e.name}`);
      //     console.log(`err.errCode: ${e.errCode}`);
      //     console.log(`err.httpStatus: ${e.httpStatus}`);
      //   });
    },
    onKeyPress(e) {
      if (e.keyCode == 27) {
        if (this.hideFullPageImage()) {
          e.stopPropagation();
        }
      }
    },
    showFullPagePdf() {
      let url = 'http://localhost:4445/engineer_test/s/oais/binary/1';
      let f = this.$refs.fullPage;

      // let pdf = document.createElement('vue-pdf-embed');
      // pdf.src = url;
      // f.appendChild(pdf);

      let embed = document.createElement('embed');
      embed.src = url;
      embed.type = 'application/pdf';
      embed.style.height = '100%';
      embed.style.width = '90%';
      f.appendChild(embed);
      f.style.display = 'block';
    },
    hideFullPageImage() {
      let fullPage = this.$refs.fullPage;
      if (!fullPage) return false;
      let result = false;
      if (fullPage.style.backgroundImage) {
        result = true;
      }
      fullPage.style.backgroundImage = '';
      fullPage.style.display = 'none';
      while (fullPage.firstChild) {
        fullPage.removeChild(fullPage.lastChild);
      }
      return result;
    },
    toggleEdsError() {
      this.$store.commit('edsError', !this.edsError);
    },
    filterKeyboardAgnostic,

    showRefs() {
      console.log(this.$refs);
    },
    calcDebugPassword() {
      this.debugPassword = currentDebugPassword();
    },
    unpPromises() {
      resolveUnpInfo(this.unp, this.ignoreBranches).then(
        (r) => {
          console.log(`for ${this.unp} got ${JSON.stringify(r)}`);
        },
        (e) => {
          console.log(`ERROR: for ${this.unp} got`);
          console.log(e);
        }
      );
      console.log('sent requests, waiting drinking beer');
    },
    freshClient,
    getChangelog() {
      ajax.changelog().then(
        (r) => {
          this.changelog = r.data.message;
        },
        (err) => {
          this.$showError('Ошибка', err);
          this.changelog = err.data.message;
        }
      );
    },
    openDialog() {
      if (this.dialog) {
        this.dialog.show();
      }
    },

    assignDialogRef(dialog) {
      this.dialog = dialog;
    },
  },
  validations() {
    return {
      doc: {
        client: clientValidation(),
      },
    };
  },
  data() {
    return {
      binaries: [
        {
          id: 1,
          url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwAQMAAABtzGvEAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAGUExURf83tv/7/eBn5ekAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAWdEVYdFNvZnR3YXJlAFBhaW50Lk5FVCA1LjH3g/eTAAAAtGVYSWZJSSoACAAAAAUAGgEFAAEAAABKAAAAGwEFAAEAAABSAAAAKAEDAAEAAAADAAAAMQECAA4AAABaAAAAaYcEAAEAAABoAAAAAAAAAKOTAADoAwAAo5MAAOgDAABQYWludC5ORVQgNS4xAAMAAJAHAAQAAAAwMjMwAaADAAEAAAABAAAABaAEAAEAAACSAAAAAAAAAAIAAQACAAQAAABSOTgAAgAHAAQAAAAwMTAwAAAAABU9XsNJigOlAAAAdUlEQVQoz2P4DwZkUz/+H/9nD6Tq20HUn/rmP/UYlD3zH6DKf/aMPz7+AVKCPz7+AFKGIOq/nMHHHyDKAk59YPjPV/H5TzHD/56aZDQqmeH/gT/p/5SB1D/2f+oQip3h/8F//P+B1MP//P/54RRlHiOL+v8fAE3i9o76F2LvAAAAAElFTkSuQmCC',
          mimeType: 'image/png',
        },
        {
          id: 2,
          url: 'http://localhost:4445/engineer_test/s/oais/binary/32',
          mimeType: 'image/png',
        },
        {
          id: 3,
          url: 'data:application/pdf;base64,JVBERi0xLjIgDQo5IDAgb2JqDQo8PA0KPj4NCnN0cmVhbQ0KQlQvIDkgVGYoVGVzdCknIEVUDQplbmRzdHJlYW0NCmVuZG9iag0KNCAwIG9iag0KPDwNCi9UeXBlIC9QYWdlDQovUGFyZW50IDUgMCBSDQovQ29udGVudHMgOSAwIFINCj4+DQplbmRvYmoNCjUgMCBvYmoNCjw8DQovS2lkcyBbNCAwIFIgXQ0KL0NvdW50IDENCi9UeXBlIC9QYWdlcw0KL01lZGlhQm94IFsgMCAwIDk5IDkgXQ0KPj4NCmVuZG9iag0KMyAwIG9iag0KPDwNCi9QYWdlcyA1IDAgUg0KL1R5cGUgL0NhdGFsb2cNCj4+DQplbmRvYmoNCnRyYWlsZXINCjw8DQovUm9vdCAzIDAgUg0KPj4NCiUlRU9G',
          mimeType: 'application/pdf',
        },
        {
          id: 4,
          url: 'http://localhost:4445/engineer_test/s/oais/binary/2',
          mimeType: 'application/pdf',
        },
      ],
      fullPageDiv: null,
      console,
      debugPassword: this.calcDebugPassword(),
      changelog: null,
      dialog: null,
      showMyModal: false,
      msg: 'This message will be dismissed after 3 seconds',
      model: 7009317,
      dc: null,
      dcDate: null,
      models: [],
      state: {
        // date: new Date()
        date1: null,
        date2: null,
      },
      doc: {
        client: freshClient(),
      },
      unp: 100003006,
      ignoreBranches: false,
      blanks: (function() {
        let result = [];
        for (let id = 1; id <= 100; id++) {
          result.push({ id, name: 'AO' + (1e9 + id + '').slice(-7) });
        }
        return result;
      })(),
      blankId: null,
      permission: _cloneDeep(testOaisPermission),
    };
  },
};
</script>
