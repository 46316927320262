import VueResource from 'vue-resource';
import _isArray from 'lodash/isArray';
import _isEmpty from 'lodash/isEmpty';
import _isFunction from 'lodash/isFunction';
import _isObject from 'lodash/isObject';
import _cloneDeep from 'lodash/cloneDeep';
import { patterns } from '@/lib/consts';
import { isUnpValid } from '@/lib/funcs';

Vue.use(VueResource);
export const eventBus = new Vue();
eventBus.res = eventBus.$resource(
  '',
  {},
  {
    getUserInfo: { method: 'GET', url: 'uinfo/s' },
    getUserSettings: { method: 'GET', url: 'uinfo/settings' },
    putUserSettings: { method: 'POST', url: 'uinfo/settings' },
    logout: { method: 'GET', url: 'logon/logout' },
    login: { method: 'POST', url: 'logon/login' },
    changePassword: { method: 'POST', url: 'logon/changePassword' },
    getRef: { method: 'GET', url: 'r/{refName}?v={v}' },
    getTariffs: { method: 'GET', url: 'r/tariff' },
    getServices: { method: 'GET', url: 'r/service' },
    getSubserviceSets: { method: 'GET', url: 'r/subserviceSet' },
    fetchUnpInfo: { method: 'GET', url: 'i/byUnp/{unp}' },
    getBlanks: { method: 'GET', url: 'b/blanks?t={type}&s=1&sk={sk}&l={pageSize}' },
    // getPermissions: { method: 'GET', url: 'perm/p?sk={sk}&l={pageSize}' },
    // getPermissionsCount: { method: 'GET', url: 'perm/pc' },
    findPermByNum: { method: 'GET', url: 'perm/findPermByNum?pn={pn}' },
    findPermByCn: { method: 'GET', url: 'perm/findPermByCn' },
    getGaiInfo: { method: 'GET', url: 'i/gai/{cn}' },
    getExistingEkmt: { method: 'GET', url: 'i/getExistingEkmt/' },
    nextDocId: { method: 'POST', url: 'docs/nextDocId/{group}/{subgroup}' },

    // #region Заявления на перенос отметки:
    getTACount: { method: 'GET', url: 'ta/count' },
    getTAs: { method: 'GET', url: 'ta/l?sk={sk}&l={pageSize}' },
    getTA: { method: 'GET', url: 'ta/get/{id}' },
    searchTAbyCN: { method: 'GET', url: 'ta/searchCN/{cn}' },
    searchTAbyId: { method: 'GET', url: 'ta/searchId/{id}' },
    saveTA: { method: 'POST', url: 'ta/put' },
    // #endregion

    // #region Заявления на внесение изменений информации о выданном разрешении (версия 3)
    getTasCount3: { method: 'GET', url: 'ta3/count' },
    getTas3: { method: 'GET', url: 'ta3/l?sk={sk}&l={pageSize}' },
    getTa3: { method: 'GET', url: 'ta3/get/{id}' },
    searchTabyCn3: { method: 'GET', url: 'ta3/searchCn/{cn}' },
    searchTabyId3: { method: 'GET', url: 'ta3/searchId/{id}' },
    saveTa3: { method: 'POST', url: 'ta3/save' },
    // #endregion

    // #region Заявления на перенос отметки (версия 2):
    getTasCount2: { method: 'GET', url: 'ta2/count' },
    getTas2: { method: 'GET', url: 'ta2/l?sk={sk}&l={pageSize}' },
    getTa2: { method: 'GET', url: 'ta2/get/{id}' },
    searchTabyCn2: { method: 'GET', url: 'ta2/searchCn/{cn}' },
    searchTabyId2: { method: 'GET', url: 'ta2/searchId/{id}' },
    saveTa2: { method: 'POST', url: 'ta2/save' },
    // #endregion

    searchPerms4Ta: { method: 'GET', url: 'i/searchPerms4Ta?type={type}&term={term}' },
    searchPerms4OtherCert: { method: 'GET', url: 'i/searchPerms4OtherCert?type={type}&term={term}' },

    // #region отказы в обслуживании:
    getRefuses2: { method: 'GET', url: 'refuses/?t={type}&sk={sk}&ps={pageSize}' },
    getRefuse2: { method: 'GET', url: 'refuses/{id}' },
    delRefuse2: { method: 'DELETE', url: 'refuses/{id}' },
    saveRefuse2: { method: 'POST', url: 'refuses/' },
    // getRefuseCount: { method: 'GET', url: 'perm/rc' },
    // getRefuses: { method: 'GET', url: 'perm/r?sk={sk}&l={pageSize}' },
    // getRefuse: { method: 'GET', url: 'perm/r/{guid}' },
    // delRefuse: { method: 'DELETE', url: 'perm/r/{guid}' },
    // saveRefuse: { method: 'POST', url: 'perm/r' },
    // #endregion

    getPermission: { method: 'GET', url: 'perm/p/{guid}?transfer={tr}' },
    savePermission: {
      method: 'POST',
      url: 'perm/p?transferedGuid={transferedGuid}&applicationId={applicationId}',
    },
    delPermission: { method: 'DELETE', url: 'perm/p/{guid}' },
    passPerms: { method: 'POST', url: 'b/passPerms/' },
    changeBlank: { method: 'POST', url: 'perm/changeBlank' },

    searchEripPaymentsByAccount: { method: 'GET', url: 'pa/searchEripByAccount/{searchTerm}/{onlyActive}' },
    searchEripPaymentsByTxId: { method: 'GET', url: 'pa/searchEripByTxId/{searchTerm}/{onlyActive}' },
    searchWebpayPayments: { method: 'GET', url: 'pa/searchWebpay/{searchTerm}' },
    findUsedPaymentOrders4Permission: {
      method: 'POST',
      url: 'pa/findUsedPaymentOrders4Permission',
    },
    findUsedPaymentOrders4Act: {
      method: 'POST',
      url: 'pa/findUsedPaymentOrders4Act',
    },

    changelog: { method: 'GET', url: 'i/changelog' },
    getVersion: { method: 'GET', url: 'i/version' },
    changePass: { method: 'POST', url: 'uinfo/cp' },
    // passBlanks: { method: 'POST', url: 'b/passBlanks' },
    blankOps: { method: 'POST', url: 'b/{operation}Blanks' },

    reportJSON: {
      method: 'GET',
      url: 'rpt/reportJSON?from={from}&to={to}&type={type}',
    },

    // #region Контрагенты:
    findCounteragents: { method: 'GET', url: 'docs/findCounteragents?unp={unp}&name={name}' },
    getCounteragent: { method: 'GET', url: 'docs/counteragent/{id}' },
    deleteCounteragent: { method: 'DELETE', url: 'docs/counteragent/{id}' },
    saveCounteragent: { method: 'POST', url: 'docs/counteragent' },
    // #endregion

    // #region договоры:
    // getContractsOfCounteragent: {
    //   method: 'GET',
    //   url: 'docs/contractsOfCounteragent?counteragentId={counteragentId}&serviceId={serviceId}',
    // },
    contractsFilter: { method: 'POST', url: 'docs/contractsFilter' },
    getContract: { method: 'GET', url: 'docs/contract/{id}' },
    getContractAndInfo: { method: 'GET', url: 'docs/getContractAndInfo/{id}' },
    deleteContract: { method: 'DELETE', url: 'docs/contract/{id}' },
    saveContract: { method: 'POST', url: 'docs/contract' },
    printContract: { method: 'GET', url: 'docs/printContract/{id}' },
    // loadContractInfo: { method: 'GET', url: 'docs/loadContractInfo?contractNum={contractNum}&serviceId={serviceId}' },
    nextContractNum: { method: 'GET', url: 'docs/nextContractNum?serviceId={serviceId}' },
    findContractsByNum: {
      method: 'GET',
      url: 'docs/findContractsByNum?serviceId={serviceId}&contractNum={contractNum}',
    },
    // #endregion

    // // совмещенные договоры-акты:
    // casFilter: { method: 'POST', url: 'docs/casFilter' },
    // getCa: { method: 'GET', url: 'docs/ca/{id}' },
    // getCaAndInfo: { method: 'GET', url: 'docs/getCaAndInfo/{id}' },
    // deleteCa: { method: 'DELETE', url: 'docs/ca/{id}' },
    // saveCa: { method: 'POST', url: 'docs/ca' },
    // printCa: { method: 'GET', url: 'docs/printCa/{id}' },
    // nextCaNum: { method: 'GET', url: 'docs/nextCaNum?serviceId={serviceId}' },

    // #region Сообщения:
    getUserMessage: { method: 'GET', url: 'msg/my/{id}' },
    getUserMessages: { method: 'GET', url: 'msg' },
    markMessageOpened: { method: 'POST', url: 'msg/markOpened/{id}' },
    markMessageRead: { method: 'POST', url: 'msg/markRead/{id}' },
    getUnreadCount: { method: 'GET', url: 'msg/unread' },
    // #endregion

    // #region Акты:
    getActs: { method: 'GET', url: 'docs/acts?contractId={contractId}' },
    actsFilter: { method: 'POST', url: 'docs/actsFilter' },
    getAct: { method: 'GET', url: 'docs/act/{id}?skipCerts={skipCerts}&skipInfo={skipInfo}' },
    getActAndSpareCerts: { method: 'GET', url: 'docs/getActAndSpareCerts/{id}' },
    deleteAct: { method: 'DELETE', url: 'docs/act/{id}' },
    saveAct: { method: 'POST', url: 'docs/act' },
    nextActNumAndSpareCerts: { method: 'GET', url: 'docs/nextActNumAndSpareCerts?serviceId={serviceId}' },
    nextActNum: { method: 'GET', url: 'docs/nextActNum?serviceId={serviceId}' },
    findActsByNum: { method: 'GET', url: 'docs/findActsByNum?serviceId={serviceId}&actNum={actNum}' },
    // #endregion

    // #region сертификаты МСТО:
    mstoList: { method: 'GET', url: 'certs/mstoList/?p={page}&ps={pageSize}' },
    mstoListFiltered: {
      method: 'GET',
      url: 'certs/mstoListFiltered/?certNumber={certNumber}&vin={vin}&p={page}&ps={pageSize}',
    },
    getMsto: { method: 'GET', url: 'certs/msto/{mstoId}' },
    deleteMsto: { method: 'DELETE', url: 'certs/msto/{certId}' },
    saveMsto: { method: 'POST', url: 'certs/msto' },
    // #endregion

    // #region сертификаты ЕКМТ:
    ekmtList: { method: 'GET', url: 'certs/ekmtList/?p={page}&ps={pageSize}' },
    getEkmt: { method: 'GET', url: 'certs/ekmt/{certId}' },
    deleteEkmt: { method: 'DELETE', url: 'certs/ekmt/{certId}' },
    saveEkmt: { method: 'POST', url: 'certs/ekmt' },
    fetchEkmtProto: { method: 'GET', url: 'certs/ekmtProto/{id}' },
    findEkmt: { method: 'GET', url: 'certs/findEkmt/{num}' },
    // #endregion

    // #region сертификаты ОГ:
    ogList: { method: 'GET', url: 'certs/ogList/?p={page}&ps={pageSize}' },
    ogListFiltered: {
      method: 'GET',
      url: 'certs/ogListFiltered/?certNumber={certNumber}&rn={rn}&p={page}&ps={pageSize}',
    },
    getOg: { method: 'GET', url: 'certs/og/{certId}' },
    deleteOg: { method: 'DELETE', url: 'certs/og/{certId}' },
    saveOg: { method: 'POST', url: 'certs/og' },
    // #endregion

    // #region Карты доп. проверки ОГ
    getOgCard: { method: 'GET', url: 'certs/ogCard/{id}' },
    ogCardList: { method: 'GET', url: 'certs/ogCardList/?sk={sk}&l={l}' },
    ogCardListFiltered: {
      method: 'GET',
      url: 'certs/ogCardListFiltered/?number={number}&rn={rn}&sk={sk}&l={l}',
    },
    ogCardSearch: { method: 'GET', url: 'certs/ogCardSearch/?number={number}&rn={rn}' },
    saveOgCard: { method: 'POST', url: 'certs/ogCard' },
    // #endregion

    // #region ЭЦП
    sign: { method: 'POST', url: 'http://127.0.0.1:49020/sign_str' },
    // #endregion
  }
);
export const ajax = eventBus.res;
// export const ajaxModular = {
//   oais: Vue.resource('', {}, { ...oaisResources })
// };

/**
 * Если у документа нет поля id или оно не числовое, то с сервера запрашивается следующий id для указанной группы и подгруппы документов.
 * Если числовой id уже имеется, просто возвращаем исходный объект.
 * @param {Object} model документ
 * @param {Number} group номер группы документов
 * @param {Number} subgroup Номер подгруппы документов
 * @returns {Object} документ в неизменном виде или с присвоенным id.
 */
export async function assignId(model, group, subgroup) {
  if (typeof model.id === 'number') {
    // Если модель уже содержит id, просто ее возвращаем
    return model;
  } else {
    // Получим новый id для документа DocumentGroups.OG_CARD
    return ajax.nextDocId({ group, subgroup }, { group, subgroup }).then((r) => {
      model.id = r.data.content;
      return model;
      // return Promise.resolve(model);
    });
  }
}

const gaiCache = {}; // кэш данных о регистрации, полученных от ГАИ
/**
 * Запрос информации от сервиса ГАИ с кэшированием на стороне клиента
 * @param {String} cn техпаспорт, по которому ищем ТС
 * @param {function(Object)} cb - callback, которому передается описание ТС в виде объекта {id:xxx, text:"yyyyy"}
 */
export const getGaiRegInfo = (cn, cb) => {
  const emptyAnswer = { id: null, text: '' };
  function describeGaiInfo(d) {
    if (d) {
      return {
        id: d.regId,
        text: d.model + ' ' + d.rn + ' (' + d.mass + 'кг)',
        info: d,
      };
    } else {
      return { id: null, text: '' };
    }
  }
  if (!_isFunction(cb)) return;

  if (!cn || !cn.match(patterns.cn)) {
    cb(emptyAnswer);
    return;
  }
  cn = cn.replace(/\s+/, '');
  var dd = gaiCache[cn];
  if (typeof dd !== 'undefined') {
    cb(describeGaiInfo(dd));
    return;
  }
  ajax.getGaiInfo({ cn }).then(
    (r) => {
      cb(describeGaiInfo(r.data.content));
      gaiCache[cn] = r.data.content;
    },
    () => {
      cb(emptyAnswer);
    }
  );
};

const unpCache = {}; // кэш данных о юрлицах по УНП
/**
 * Здесь на время ожидания ответа от бэкенда хранятся промисы http-запросов.
 * Нужно, чтобы не дублировать обращения к бэкенду.
 */
const unpPromices = {};

/**
 * Объект для поддержки паттерна short circuit
 */
const shortCircuit = {
  /**
   * Для паттерна short circuit. Это timestamp, после которого снова начинаем снова запрашивать у бэкенда информацию по УНП
   */
  scExpiration: 0,

  /**
   * Счетчик количества неудачных попыток получения информации, после которых нужно прекратить запрашивать инфу по УНП
   * Для паттерна short circuit.
   */
  scCounter: 0,
  increaseCounter() {
    this.scCounter++;
    if (this.scCounter >= 20) {
      this.scExpiration = Date.now() + 1000 * 60 * 4;
    }
  },
  isIn() {
    return Date.now() <= this.scExpiration;
  },
  reset() {
    this.scCounter = 0;
    this.scExpiration = 0;
  }
};

function calcUnpInfo(dd, ignoreBranches) {
  if (ignoreBranches || _isEmpty(dd.branches)) {
    return dd;
  } else {
    return { ...dd, address: '', name: '⚠⚠⚠ Имеются обособленные филиалы' };
  }
}

/**
 * Запрос информации о субъектах хозяйствования от сервиса Налоговой с кэшированием на стороне клиента.
 * Если субъект не имеет обособленных филиалов (либо передан параметр ignoreBranches), информация передается в callback.
 * В противном случае передаются пустые строки
 * @param {Number} unp УНП, по которому ищем субъект хозяйствования
 * @param {Boolean} ignoreBranches - Управляет передаваемыми в callback данными.
 */
export const resolveUnpInfo = (unp, ignoreBranches) => {
  if (!isUnpValid(unp)) return Promise.reject(new Error('Unp invalid'));
  var dd = unpCache[unp];
  if (_isObject(dd) && !_isArray(dd)) {
    if (dd.unp || shortCircuit.isIn()) {
      return Promise.resolve(calcUnpInfo(dd, ignoreBranches));
    }
  }
  if (shortCircuit.isIn()) {
    return Promise.resolve(calcUnpInfo({ unp: 0, name: '', address: '', branches: null }, ignoreBranches));
  }

  let fetchPromise = unpPromices[unp];
  if (!fetchPromise) {
    fetchPromise = ajax.fetchUnpInfo({ unp });
    unpPromices[unp] = fetchPromise;
  }

  delete unpCache[unp];
  return fetchPromise
    .finally(() => {
      setTimeout(() => delete unpPromices[unp], 1000);
    })
    .then(
      (r) => {
        if (typeof unpCache[unp] !== 'undefined') return calcUnpInfo(unpCache[unp], ignoreBranches);
        let d = _cloneDeep(r.data.content);
        if (d.stub) {
          shortCircuit.increaseCounter();
        } else {
          shortCircuit.reset();
        }
        if (!_isEmpty(d.branches)) {
          d.branches.unshift({ b: -1, n: d.name, a: d.address });
        }
        unpCache[unp] = d;
        return calcUnpInfo(d, ignoreBranches);
      },
      (e) => {
        shortCircuit.increaseCounter();
        Promise.reject(new Error(e));
      }
    );
};
