import ConfirmDialogVue from '@/controls/ConfirmDialog.vue';
import { loadComponentState, storeComponentState } from '@/lib/funcs';
export const modifiable = {
  beforeRouteLeave(to, from, next) {
    if (_isEqual(this.m, this.m_)) {
      next();
    } else {
      this.$askLeave(() => next(), () => next(false));
    }
  },
  methods: {
    $askLeave(callbackYes, callbackNo) {
      this.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: 'Есть несохраненные изменения. Действительно хотите выйти?',
          buttons: 0,
          customButtons: [
            { id: 2, name: 'Уйти', class: 'btn-warning' },
            { id: 1, name: 'Остаться', class: 'btn-success' },
          ],
          focusButton: 1
        },
        width: 400,
        height: 180,
        title: 'Подтверждение',
        callback: function(b) {
          if (b === 2) {
            if (callbackYes) callbackYes();
          } else {
            if (callbackNo) callbackNo();
          }
        },
      });
    },
    $askCanClose(cb1) {
      let eq = _isEqual(this.m, this.m_);
      if (eq) {
        cb1(1);
      } else {
        this.$askLeave(() => cb1(1), () => cb1());
      }
    },
  },
};

export const tabbedSubroutes = {
  methods: {
    onRoute(to, from, next) {
      let splittedPath = to.fullPath.split('/');
      if (splittedPath.length < 3) {
        let storedRoute = loadComponentState(this.componentName, null);
        if (storedRoute) {
          next(storedRoute);
        } else {
          next(splittedPath.slice(0, splittedPath.length).join('/') + '/' + this.defaultSubRoute);
        }
      } else {
        next();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.onRoute(to, from, next);
    storeComponentState(this.componentName, to.fullPath);
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.onRoute(to, from, next);
    });
    next();
  },
  beforeRouteLeave(to, from, next) {
    storeComponentState(this.componentName, from.fullPath);
    next();
  },
};

export const askThenDo = {
  methods: {
    /**
     * Выводит модальный диалог и кнопки "Да" и "Нет". Вызывает переданный callback при утвердительном ответе.
     * @param {string} text Текст, отображаемый пользователю.
     * @param {Function} callback callback
     * @param {Object} payload Объект данных, передаваемый в callback.
     */
    askThenDo(text, callback, payload, width = 400, height = 180) {
      this.$dlg.modal(ConfirmDialogVue, {
        params: {
          message: text,
          buttons: 6,
          focusButton: 2,
        },
        width,
        height,
        title: 'Подтверждение',
        callback: function(b) {
          if (b === 2 && callback) callback(payload);
        },
      });
    },
  },
};

export const pagedCommonList = {
  data() {
    return {
      pg: { currentPage: 1 },
      pageSize: 20,
      totalItems: 10000,
      blockUI: false,
      list: [],
    };
  },
  props: {
    /**
     * Сюда передается объект-загрузчик vue-resource
     */
    ajaxLoader: {
      type: Function,
      required: true,
    },
    /**
     * условия поиска для объекта-загрузчика vue-resource(если есть).
     * В этом компоненте к условиям поиска добавится информация о пагинации
     */
    ajaxParams: {
      type: Object,
      required: true,
    },
  },
  methods: {
    load() {
      let vm = this;
      vm.blockUI = true;
      vm.list = [];
      vm.ajaxLoader({ ...vm.ajaxParams, sk: vm.pageSize * (vm.pg.currentPage - 1), l: vm.pageSize })
        .then(
          r => {
            vm.list = r.data.content[0];
            vm.totalItems = vm.calculateTotalItems(r.data.content[1]);
          },
          err => {
            vm.$showError('Ошибка загрузки', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    },
    calculateTotalItems(itemsCountFromServer) {
      let vm = this;
      if (itemsCountFromServer != -1) return itemsCountFromServer;
      return vm.totalItems;
    },
  },
  mounted() {
    if (Object.keys(this.ajaxParams).length == 0) {
      this.load();
    }
  },
  watch: {
    ajaxLoader: function(val, oldVal) {
      this.load();
    },
    ajaxParams: {
      handler: function(val, oldVal) {
        // this.$log.debug('ajaxParams changed: ', oldVal, val);
        this.load();
      },
      deep: true,
    },
  },
};
