<template>
  <div class="container">
    <div class="fixed-header">
      <span class="fixed-header-title">{{ admProcName }}: отказ в выдаче {{ refuseNumber }}</span>
      <span class="fa-pull-right">
        <button
          class="btn btn-success icon-button"
          type="button"
          title="Сохранить"
          @click="save()"
          :disabled="$v.m.$invalid || initialFetchFailure"
        >
          <i class="fa fa-save fa-2x"></i>
        </button>
        <button
          class="btn btn-danger icon-button"
          type="button"
          title="Отмена"
          @click="$router.push({ path: '/refuses' })"
        >
          <i class="fa fa-window-close fa-2x"></i>
        </button>
      </span>
    </div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <div class="under-fixed-header">
      <form name="refuseForm" novalidate onsubmit="return false" v-show="!initialFetchFailure">
        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'red-text': $v.m.cn.$invalid }">Техпаспорт</label>
          <div class="col-sm-8 no-pad">
            <input
              type="text"
              class="form-control"
              v-model="m.cn"
              @blur="gai"
              placeholder="Номер техпаспорта"
              @input="forceUpper($event, m, 'cn')"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'red-text': $v.m.dsId.$invalid }">Место обращения</label>
          <div class="col-sm-8 no-pad">
            <vue-single-select v-model="m.dsId" :options="ds" placeholder="Место обращения" />
          </div>
        </div>

        <div class="form-group row">
          <label class="control-label col-sm-4" :class="{ 'red-text': $v.m.date.$invalid }">Дата обращения</label>
          <div class="col-sm-8 no-pad">
            <rome-datepicker
              v-model="m.date"
              class="form-control"
              :options="{ time: false, inputFormat: 'DD.MM.YYYY' }"
              modelFormat="YYYY-MM-DD"
              placeholder="Дата обращения"
            />
          </div>
        </div>
        <div class="panel panel-default row">
          <div class="panel-heading">Заявитель</div>
          <div class="panel-body">
            <div class="container-fluid">
              <client-component v-model="m.client" :v="$v.m.client" :useBranches="false" />
            </div>
          </div>
        </div>
        <h3 class="text-center">Причины отказа</h3>
        <div class="icheck-primary" v-for="r in reasons" :key="r.id">
          <input type="checkbox" :id="'df' + r.id" v-model="m.reasons[r.id - 1]" />
          <label :for="'df' + r.id" :title="r.description">{{ r.name }}</label>
        </div>
      </form>
    </div>
    <span v-if="debugMode">{{ m }}</span>
  </div>
</template>

<script>
import RomeDatepicker from '@/controls/RomeDatepicker';
import VueSingleSelect from '@/controls/vue-single-select/VueSingleSelect';
import ClientComponent from '@/controls/ClientComponent';
import { forceUpper, freshClient } from '@/lib/funcs';
import { patterns, applicationRefuseReasons } from '@/lib/consts'
import { mapGetters } from 'vuex';
import { getGaiRegInfo } from '@/api/api';
import { regex, clientValidation } from '@/lib/validators';
import { required, between } from 'vuelidate/lib/validators';
import { modifiable } from '@/lib/mixins';
import { sign } from '@/lib/sign';
export default {
  data() {
    return {
      editMode: null,
      gaiFetch: false,
      gaiInfo: null,
      patterns,
      blockUI: false,
      initialFetchFailure: false,
      reasons: applicationRefuseReasons,
      m: {
        id: null,
        cn: null,
        client: freshClient(),
        date: moment().format('YYYY-MM-DD'),
        dsId: null,
        // 31 элемент, по числу значащих бит в знаковом целом числе
        reasons: Array.apply(null, { length: 31 }).map(() => false),
        admProcType: null,
      },
    };
  },
  validations() {
    return {
      m: {
        admProcType: { required, b: between(1, 4) },
        date: { required },
        dsId: { required },
        client: clientValidation(true),
        cn: { required, pattern: regex(patterns.cn) },
      },
    };
  },
  components: {
    VueSingleSelect,
    RomeDatepicker,
    ClientComponent,
  },
  methods: {
    gai() {
      let vm = this;
      vm.gaiFetch = 1;
      getGaiRegInfo(vm.m.cn, x => {
        vm.gaiInfo = x.text;
        vm.gaiFetch = 0;
      });
    },
    validate() {
      let message = '';
      let m = this.m;
      if (m.reasons.every(el => !el)) {
        message = message + 'Не задано ни одной причины отказа\n';
      }
      if (message) {
        this.$dlg.alert(message.replace(new RegExp('\\n'), '<br>'), { messageType: 'error' });
      }
      return !message;
    },
    save() {
      let vm = this;
      if (!vm.validate()) {
        return;
      }
      if (_isEqual(vm.m, vm.m_)) {
        vm.$log.debug('no changes');
        vm.$router.go(-1);
      } else {
        vm.blockUI = true;
        sign(vm.m)
          .then(ajax.saveRefuse2)
          .then(r => {
            vm.m_ = _cloneDeep(vm.m);
            vm.$dlg.toast('Сохранено', { messageType: 'success', closeTime: 3 });
            vm.$router.go(-1);
          })
          .catch(err => {
            vm.$showError(null, err);
          })
          .finally(() => {
            vm.blockUI = false;
          });
      }
    },
    forceUpper,
  },
  computed: {
    ...mapGetters(['userSettings', 'ds', 'debugMode', 'services']),
    admProcName() {
      return (this.services.find(el => el.id == this.m.admProcType) || { short_name: '' }).short_name;
    },
    refuseNumber() {
      if (this.m.id) return '№ О-' + this.m.id;
      else return '(новый)';
    },
  },
  mounted() {
    let vm = this;
    vm.m.dsId = vm.userSettings.curDsId;
    vm.m_ = _cloneDeep(vm.m);

    let id = vm.$route.params.id;
    if (id) {
      vm.editMode = 'редактирование';
      vm.blockUI = true;
      ajax
        .getRefuse2({ id })
        .then(
          r => {
            vm.m = r.data.content;
            vm.m_ = _cloneDeep(vm.m);
          },
          err => {
            vm.initialFetchFailure = true;
            vm.$showError('error', err);
          }
        )
        .finally(() => {
          vm.blockUI = false;
        });
    } else {
      let admProcType = vm.$route.params.admProcType;
      if (admProcType >= 1 && admProcType <= 4) {
        vm.m.admProcType = admProcType;
        vm.editMode = 'новый';
        vm.m.dsId = vm.userSettings.curDsId;
        vm.m_ = _cloneDeep(vm.m);
        // vm.$validator.validate()
      } else {
        vm.$router.go(-1);
      }
    }
  },
  mixins: [modifiable],
};
</script>

<style></style>
