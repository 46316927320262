import moment from 'moment';

let now = moment(new Date()).toISOString(true);
let validTo = moment(new Date()).add(2, 'year').toISOString(true);

export const testOaisPermission = {
  applId: 3,
  mId: 7007585,
  cId: 17,
  aId: 79,
  cn: 'ACA056257',
  bId: 19947580,
  dcDSId: 251845588,
  pDSId: 251845588,
  permNumber: 'AП0150249',
  pPeriod: 12,
  dc: 'ЩГ 702613',
  dl: '10 40 46415',
  regNum1: 'ЕЕ5743-5',
  regNum2: null,
  y: 1997,
  ow: {
    t: 0,
    n: 'КЪЫЕЖМАЧЫЦЮЧЯЪЭПХОЪЩЧРХИБЯУЖИЦ',
    u: 807512763,
    b: null,
  },
  cust: { t: 0, n: 'ЮСКСЭХСГРЗ', u: null, b: null },
  p1: {
    payments: [
      { t: 6, s: 12, docDate: '2024-01-02', docNum: '6520' },
    ],
  },
  pDate: now,
  dcDate: now,
  validTo,
  guid: null,
  mass: 580,
  kdrId: null,
  vin: 'ЪБЭЕШОРЩООИМЗАЯЛВ',
  ton: 0,
  mark: 0,
  agr: 0,
  ap: 1,
  newAuto: 0,
  el: 0,
  spec: 0,
  mintrans: 0,
  wasTransfered: 0,
  transferType: 0,
  fromGai: 0,
  cr: 0,
  gaiId: null,
  tdrId: 4,
  pS: 0,
  tS: 0,
  actId: null,
  notes: 'my notes\n\t123 ' + new Date(),
};
