<template>
  <div>
    <div v-html="message" />
    <div class="row">
      <div class="col-xs-12" style="text-align:right">
        <button
          v-for="b in allButtons"
          :key="b.id"
          class="btn"
          :class="b.class?b.class:'btn-success'"
          style="margin-right: 5px;"
          type="button"
          @click="close(b.id)"
          :ref="'btn' + b.id"
        >{{b.name}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Message'
    },
    /**
     * Состав стандартных кнопок. Битовая маска. Значение бита является идентификатором кнопки, передаваемым в callback.
     * 1 - OK
     * 2 - Да
     * 4 - Нет
     * 8 - Отмена
     */
    buttons: {
      type: Number,
      default: 1
    },
    /**
     * Свои собственные кнопки (помимо стандартных). Отображаются левее стандартных.
     * @example
     * [
     *   {
     *     id: 1, // идентификатор кнопки, он же передаваемое в callback значение
     *     name: 'Уйти', // Надпись на кнопке
     *     class: 'btn-warning' // Класс кнопки для bootstrap3
     *   }
     * ]
     */
    customButtons: {
      type: Array,
      required: false,
      default() { return []; }
    },
    /**
     * Бит, соответствующий той кнопке, на которую надо установить фокус по умолчанию (1, 2, 4, 8...)
     */
    focusButton: {
      type: Number,
      required: false
    }
  },
  methods: {
    close(buttonId) {
      this.$emit('close', buttonId);
    }
  },
  mounted() {
    if (!this.focusButton) return
    let b = this.$refs['btn' + this.focusButton]
    if (b) {
      setTimeout(() => b[0].focus())
    }
  },
  computed: {
    allButtons() {
      let standardButtons = [
        { id: 1, name: 'OK', class: 'btn-default', },
        { id: 2, name: 'Да', class: 'btn-success', },
        { id: 4, name: 'Нет', class: 'btn-warning', },
        { id: 8, name: 'Отмена', class: 'btn-warning', },
      ]
      return this.customButtons.concat(standardButtons.filter((el) => el.id & this.buttons))
    }
  }
};
</script>

<style scoped>
button {
  margin-left: 10px;
  margin-top: 10px;
}
</style>
