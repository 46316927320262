import axios from 'axios'
import { appContextPath } from '@/../generated/js/generatedAppInfo.js'
import logger from '@/lib/logger'
logger.setOptions({
  logLevel: 'info'
})

// тут должны быть настройки для axios:
axios.defaults.baseURL = `/${appContextPath}/s/`

/**
 * Создать объект описания ошибки
 * @param {*} response Ответ на запрос HTTP (при наличии)
 * @param {*} errCode Код статуса. Обычно берется из стандартной обертки ответа API
 * @param {String} msg Сообщение. Обычно берется из стандартной обертки ответа API
 * @returns Объект, описывающий ошибку, совместимый с объектом AxiosError
 */
export function AxiosErrorFromReponse(response, errCode, msg) {
  this.response = response
  this.errCode = errCode
  if (msg) {
    this.message = msg
  }
  return this
}

/**
 * Заглушка. сюда нужно будет записать основной экземпляр vue после его создания.
 */
export const VueHolder = {
  mainVue: {
    $store: { commit() {} },
    $router: { push() {} },
    $route: { fullPath: '' },
  },
}

axios.interceptors.response.use((response) => {
  let ct = response.headers['content-type'] || response.headers['Content-Type']
  if (!(ct && ct.toLowerCase().startsWith('application/json'))) {
    logger.error('Expected json, got something else')
    return Promise.reject(new AxiosErrorFromReponse(response, -13, 'Expected json, got something else'))
  }
  let data = response.data
  let mainVue = VueHolder.mainVue

  if (typeof data.errCode !== 'number') {
    return response
  }

  switch (data.errCode) {
    case 0: // all OK
      return response
    case 401: // redirect to login page
      logger.error('Auth required')
      Vue.$log.error('Auth required')
      mainVue.$store.commit('logout')
      if (mainVue.$route.name !== 'login') {
        mainVue.$router.push({
          name: 'login',
          query: {
            returnPath: mainVue.$route.path.endsWith('/login') ? null : mainVue.$route.fullPath,
          },
        })
      }
      return Promise.reject(new AxiosErrorFromReponse({ ...response, status: 401, statusText: 'Unauthorized' }, data.errCode, data.message))
    default:
      // error occured
      logger.error('axios response interceptor: error occured')
      return Promise.reject(new AxiosErrorFromReponse(response, data.errCode, data.message))
  }
}, null)

export default axios
