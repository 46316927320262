import axios from './axios'

export const permissionsCount = () => axios
  .get('perm/pc')
  .then((r) => r.data.content)
export const permissions = (body, { skip, pageSize }) => axios
  .get('perm/p', { params: { sk: skip, l: pageSize } })
  .then((r) => r.data.content)
// getPermissions: { method: 'GET', url: 'perm/p?sk={sk}&l={pageSize}' },

// const refuse = (body, { refuseApplication }) => axios.post(`oais/activeApplication/refuse/${!!refuseApplication}`, body)
// const createRepresentation = (body) => axios.post('oais/createRepresentation', body)
// const fulfill = (body) => axios.post('oais/fulfill', body)
// const take = (body, { number }) => axios.post(`oais/activeApplication/take/${encodeURIComponent(number)}`)
// const untake = (body, { number }) => axios.post(`oais/activeApplication/untake/${encodeURIComponent(number)}`)
// const getActiveApplications = (body, { skip, pageSize }) => axios.get('oais/activeApplications', { params: { sk: skip, l: pageSize } })
// const findTi = (body, { certNumber, regNumber }) => axios.get('oais/findTi', { params: { certNumber, regNumber } })

// const entries = {
//   permissionsCount,
// }

// export default entries
