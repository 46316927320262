<template>
  <div>
    <binary-viewer :url="url" :mime-type="mimeType" :thumbnail="false" />
    <div class="x1">
      <div style="float: right;">
        <button ref="ok" class="btn btn-success" type="button" @click="close(1)">ОК</button>
      </div>
      <div style="float: left;">
        <button class="btn btn-warning" type="button" @click="close(8)">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
import BinaryViewer from '@/components/BinaryViewer';
export default {
  props: ['url', 'mimeType'],
  components: { BinaryViewer },
  methods: {
    close(bNumber) {
      this.$emit('close', bNumber);
    }
  },
  mounted() {
    let vm = this
    setTimeout(() => {
      vm.$refs.ok.focus()
    })
  }
};
</script>

<style scoped>
.x1 {
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: -15px;
  margin-left: -15px;
};

</style>
