export default [
  {
    path: '/oais',
    component: () => import(/* webpackChunkName: "OaisPage" */ '../components/oais/OaisPage.vue'),
    children: [
      {
        path: 'applications',
        component: () => import('@/components/oais/OaisApplicationList'),
        name: 'oaisApplications',
        meta: {
          title: 'Заявления на админ. процедуру',
        },
      },
    ],
  },
  {
    path: '/oaisApplication/:number',
    component: () => import('@/components/oais/OaisApplication'),
    name: 'openOaisApplication',
    hidden: true,
    meta: {
      title: 'Заявление на ЭАП',
    },
  },
];
