<template>
  <div>
    <BlockUI v-if="blockUI">
      <i class="fa fa-pulse fa-spinner fa-3x fa-fw"></i>
    </BlockUI>
    <!-- <paginate :pageCount="pageCount" :containerClass="'pagination'" :clickHandler="pageChanged" prev-text="<"
    next-text=">" :first-last-button="!0" first-button-text="|<" last-button-text=">|"></paginate>-->
    <!-- <uib-pagination v-model="pagination" :total-items="22"></uib-pagination> -->
    <div>
      <uib-pagination
        :boundary-links="true"
        class="pagination-sm fa-pull-right"
        previous-text="‹"
        next-text="›"
        first-text="«"
        last-text="»"
        v-model="pg"
        :total-items="totalItems"
        @change="pageChanged"
        :items-per-page="pageSize"
        :max-size="7"
      />
    </div>
    <table class="table table-hover table-mc-light-blue table-striped">
      <thead>
        <tr>
          <td></td>
          <td>№ разрешения</td>
          <td>Дата</td>
          <td>Собственник</td>
          <td>Госномер</td>
          <td>Срок</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(p, i) in perms" :key="i" @dblclick="isElectronicPermission(p.fl) || edit(p)" :style="!isElectronicPermission(p.fl) && { cursor: 'pointer' }">
          <td>
            <div class="btn-group">
              <i class="fa fa-microchip fa-2x" v-if="isElectronicPermission(p.fl)" />
              <button type="button" class="btn btn-in-bar" v-else @click="edit(p)">
                <i class="fa fa-edit fa-2x" />
              </button>
            </div>
          </td>
          <td>
            {{ p.num }}
            <span v-if="fu.isTransfer(p.fl)" class="fa fa-arrows-h"></span>
          </td>
          <td>{{ russianDate(p.pDate) }}</td>
          <td>{{ p.ow.n }}</td>
          <td>{{ p.rn }}</td>
          <td>{{ p.p }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import fu, { russianDate, isElectronicPermission } from '@/lib/funcs'
import { permissionsCount, permissions } from '@/api/permission'

export default {
  data() {
    return {
      fu,
      isElectronicPermission,
      blockUI_: 0,
      perms: [],
      pg: { currentPage: 1 },
      pageSize: 15,
      totalItems: 0,
    }
  },
  computed: {
    blockUI() {
      return this.blockUI_ !== 0
    },
  },
  mounted() {
    this.getPermissionsCount()
    this.pageChanged()
  },
  methods: {
    russianDate,
    getPermissionsCount() {
      let vm = this
      vm.blockUI_ |= 2
      permissionsCount()
        .then((payload) => (vm.totalItems = payload))
        .catch((err) => vm.$showError('Ошибка', err))
        .finally(() => (vm.blockUI_ &= 0xfffffffd))
      // ajax
      //   .getPermissionsCount()
      //   .then(
      //     r => {
      //       vm.totalItems = Number(r.data.content)
      //     },
      //     err => {
      //       vm.$showError('Ошибка', err)
      //     }
      //   )
      //   .finally(() => {
      //     vm.blockUI_ &= 0xfffffffd
      //   })
    },
    loadPerms(skip) {
      let vm = this

      vm.blockUI_ |= 1
      permissions(null, { skip, pageSize: vm.pageSize })
        .then((payload) => (vm.perms = payload))
        .catch((err) => vm.$showError('Ошибка', err))
        .finally(() => (vm.blockUI_ &= 0xfffffffe))
    },
    // loadPerms(skip) {
    //   let vm = this

    //   vm._ |= 1
    //   ajax
    //     .getPermissions({ sk: skip, pageSize: vm.pageSize })
    //     .then(
    //       r => {
    //         vm.perms = r.data.content
    //       },
    //       err => {
    //         vm.$showError('Ошибка', err)
    //       }
    //     )
    //     .finally(() => {
    //       vm.blockUI_ &= 0xfffffffe
    //     })
    // },
    pageChanged() {
      this.loadPerms((this.pg.currentPage - 1) * this.pageSize)
    },
    edit(p) {
      let vm = this
      vm.$router.push({ name: 'editPerm', params: { guid: p.guid } })
    },
  },
}
</script>
